<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { customerData, encodedTrackingParams } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'nextStep', 'dispatchStepEvent'])

const processing = inject('processing')

const form = ref({
  code: '',
})
const accountVerified = ref(false)
const codeError = ref('')
const codeSentMessage = ref('')

watch(codeSentMessage, () => {
  setTimeout(() => {
    codeSentMessage.value = ''
  }, 3000)
})

const rules = computed(() => ({
  code: {
    required: helpers.withMessage('', required),
    minLength: helpers.withMessage(() => props.t('errors.minLength'), minLength(1)),
    maxLength: helpers.withMessage(() => props.t('errors.maxLength'), maxLength(6)),
    accountVerified: helpers.withMessage(
      () => codeError.value,
      () => !accountVerified.value,
    ),
  },
}))

const v$ = useVuelidate(rules, form, {})

async function verifyEmail() {
  const resp = await props.signupApi({
    url: '/account-verification',
    method: 'POST',
    fields: {
      code: form.value.code,
    },
    blocking: true,
  })

  if (resp?.errors) {
    emits('formError', resp.errors)
  }
  else {
    emits('updateCustomer', {
      isAccountVerificationRequired: false,
    })
    emits('nextStep')
  }
}

async function resendCode() {
  const resp = await props.signupApi({
    url: '/account-verification/send',
  })

  if (resp?.errors) {
    if (resp.errors[0].code === 'ACCOUNT_VERIFICATION_COMPLETED') {
      accountVerified.value = true
      codeError.value = resp.errors[0].message.replace(
        'login',
        `<a href="${memberSiteUrl}/login/?${encodedTrackingParams}">login</a>`,
      )
      v$.value.code.$touch()
    }
    else {
      emits('formError', resp.errors)
    }
  }
  else {
    codeSentMessage.value = props.t('form.newCodeSent')
  }
}
</script>

<script>
export default {
  name: 'EmailVerificationStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.verification') }}
      </p>
      <i18n-t
        keypath="form.codeSent"
        tag="p"
        scope="global"
        class="cd-mb-1rem"
      >
        <template #email>
          {{ customerData.email }}
        </template>
      </i18n-t>
      <p class="cd-mb-1rem">
        {{ t('form.codeValid') }}
      </p>
      <InputText
        v-model="form.code"
        type="text"
        name="code"
        :maxlength="6"
        :tabindex="1"
        :required="true"
        :label="t('form.verifyEmail')"
        :autofocus="true"
        :v="v$.code"
      />
      <transition name="fade">
        <div v-if="codeSentMessage.length" class="cd-text-success">
          {{ codeSentMessage }}
        </div>
      </transition>
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="2"
        :disabled="v$.$invalid || processing"
        @click.prevent="verifyEmail()"
      >
        {{ t('form.stepVerifySubmit') }}
      </button>
      <i18n-t
        keypath="form.newCode"
        tag="p"
        scope="global"
      >
        <template #sendAgain>
          <a href="#" class="" @click.prevent="resendCode()">
            {{ t('form.sendAgain') }}
          </a>
        </template>
      </i18n-t>
    </div>
  </form>
</template>
