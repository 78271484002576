<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required, requiredIf, sameAs } from '@vuelidate/validators'
import { birthdate, invalidChars, phone, zip } from '@/validation/validators'
import { states } from '@/composables/states-list'
import { customerData, encodedTrackingParams, isSponsoredPlan, presetFirstName, presetLastName, signupMilestones } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'nextStep', 'dispatchStepEvent'])

/**
 * Injections
 * - Member url is set by the end user on the mount point as data attributes
 *
 */
const memberSiteUrl = inject('memberSiteUrl')
const processing = inject('processing')
const productName = inject('productname')

onMounted(() => emits('dispatchStepEvent'))

/**
 * Form validation
 * Reactive variables capture possible error responses and their necessary UI changes
 * form <Object> ref for form inputs
 * rules <Object> vuelidate rules object
 */
const showCityState = ref(false)
const fullSsnRequired = ref(customerData.value?.fullSsnRequired ? customerData.value.fullSsnRequired : false)
const focusSsn = ref(false)
const ssnErrorMessage = ref('')
const showZipErrorMessage = ref(false)
const ssnReason = ref('')
const ssnUnique = ref(true)
const awaitSsn = ref('')

const form = ref({
  firstName: presetFirstName.value ? presetFirstName.value : customerData.value?.firstName ? customerData.value.firstName : '',
  lastName: presetLastName.value ? presetLastName.value : customerData.value?.lastName ? customerData.value.lastName : '',
  ssnPartial: '',
  ssn: '',
  birthdate: customerData.value?.birthdate ? customerData.value.birthdate : '',
  street: customerData.value?.street ? customerData.value.street : '',
  city: customerData.value?.city ? customerData.value.city : '',
  state: customerData.value?.state ? customerData.value.state : '',
  zip: customerData.value?.zip ? customerData.value.zip : '',
  phone: customerData.value?.phone ? customerData.value.phone : '',
  isConfirmedTerms: false,
})

const rules = computed(() => ({
  firstName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  lastName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  ssn: {
    minLength: helpers.withMessage(() => props.t('errors.ssnLength'), minLength(11)),
    required: helpers.withMessage(
      ssnErrorMessage.value,
      requiredIf(() => fullSsnRequired.value),
    ),
    unique: helpers.withMessage(
      () => ssnReason.value,
      () => ssnUnique.value,
    ),
  },
  ssnPartial: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => !fullSsnRequired.value),
    ),
    minLength: helpers.withMessage(() => props.t('errors.ssnPartialLength'), minLength(11)),
    maxLength: helpers.withMessage(() => props.t('errors.ssnPartialLength'), maxLength(11)),
    unique: helpers.withMessage(
      () => ssnReason.value,
      () => ssnUnique.value,
    ),
  },
  birthdate: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    birthdate: helpers.withMessage(() => props.t('errors.birthdate'), birthdate),
    minLength: helpers.withMessage(() => props.t('errors.date'), minLength(10)),
    maxLength: helpers.withMessage(() => props.t('errors.date'), maxLength(10)),
  },
  street: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    // invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  city: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => showCityState.value),
    ),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  state: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => showCityState.value),
    ),
  },
  zip: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    zip: helpers.withMessage(() => props.t('errors.zip'), zip),
  },
  phone: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    phone: helpers.withMessage(() => props.t('errors.phone'), phone),
  },
  isConfirmedTerms: {
    required: sameAs(isSponsoredPlan.value),
  },
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handles input blur
 * triggers validation on blur, not input
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
}

/**
 * SSN blur handler validates ssn and handles error responses
 *
 */
async function ssnBlur() {
  awaitSsn.value = form.value.ssn

  const resp = await props.signupApi({
    url: '/validate/ssn',
    method: 'POST',
    fields: { ssn: form.value.ssn.replace(/ /g, '') },
    blocking: false,
  })
  if (resp?.errors) {
    ssnUnique.value = false
    focusSsn.value = true
    ssnReason.value = memberSiteUrl
      ? resp.errors[0].message.replace(
          'login',
          `<a href="${memberSiteUrl}/login/?${encodedTrackingParams}">login</a>`,
        )
      : resp.errors[0].message
    v$.value.ssn.$touch()
  }
  else {
    ssnUnique.value = true
  }
}

function resetSsnPartial() {
  ssnUnique.value = true
  ssnReason.value = ''
}

function ssnChange() {
  if (form.value.ssn.length === 11 && awaitSsn.value !== form.value.ssn) {
    ssnReason.value = ''
    focusSsn.value = false
    ssnBlur()
  }
}

/**
 * Form submit
 *
 */
async function updateIdentity() {
  // Reset errors
  emits('clearFormError')
  showZipErrorMessage.value = false
  ssnErrorMessage.value = ''
  focusSsn.value = false

  const resp = await props.signupApi({
    url: '/customer/update/identity',
    method: 'POST',
    fields: {
      'firstName': form.value.firstName,
      'lastName': form.value.lastName,
      'homeAddress.street': form.value.street,
      ...(form.value.city && { 'homeAddress.city': form.value.city }),
      ...(form.value.state && { 'homeAddress.state': form.value.state }),
      'homeAddress.zip': form.value.zip,
      'homePhone': form.value.phone,
      'identity.birthDate': form.value.birthdate,
      ...(!form.value.ssn && { 'identity.ssnPartial': form.value.ssnPartial.slice(-4) }),
      ...(form.value.ssn && { 'identity.ssn': form.value.ssn }),
      ...(customerData.value?.securityQuestionAnswer && {
        'securityQuestionAnswer.securityQuestionId': Number.parseInt(customerData.value?.securityQuestionAnswer.securityQuestionId),
        'securityQuestionAnswer.answer': customerData.value.securityQuestionAnswer.answer,
      }),
      ...(isSponsoredPlan.value && {
        isConfirmedTerms: customerData.value.isConfirmedTerms
          ? customerData.value.isConfirmedTerms
          : form.value.isConfirmedTerms,
        isBrowserConnection: true,
      }),
    },
    blocking: true,
    signupMilestone: signupMilestones.SECURITY_INFO_RECEIVED,
  })

  if (resp?.errors) {
    const errorArr = [
      'SSN_NO_MATCH',
      'FULL_SSN_REQUIRED',
      'SSN_REQUIRED',
      'SSN_USED',
      'INVALID_PARTIAL_SSN',
      'INVALID_SSN',
      'PARTIAL_SSN_REQUIRED',
      'ZIP_LOOKUP_FAILED',
      'FIELD_INVALID_CHARACTERS',
    ]

    const errorIndex = resp.errors.findIndex(x => errorArr.includes(x.code))

    // Some errors we should update the UI
    if (errorIndex !== -1) {
      const errorCode = resp.errors[errorIndex].code

      if (errorCode === 'ZIP_LOOKUP_FAILED') {
        showCityState.value = true
        showZipErrorMessage.value = true
      }
      else if (errorCode === 'SSN_USED') {
        ssnUnique.value = false
        focusSsn.value = true
        ssnReason.value = memberSiteUrl
          ? resp.errors[0].message.replace(
              'login',
              `<a href="${memberSiteUrl}/login/?${encodedTrackingParams}">login</a>`,
            )
          : resp.errors[0].message
      }
      else if (errorCode === 'SSN_NO_MATCH'
        || errorCode === 'FULL_SSN_REQUIRED'
        || errorCode === 'SSN_REQUIRED'
        || errorCode === 'INVALID_PARTIAL_SSN') {
        fullSsnRequired.value = true
        focusSsn.value = true
        emits('formError', [{ code: 'fullSsnRequired' }])
      }
      else if (errorCode === 'PARTIAL_SSN_REQUIRED') {
        fullSsnRequired.value = false
      }
      else if (errorCode === 'FIELD_INVALID_CHARACTERS') {
        const field = resp.errors[errorIndex].field.split('.').pop()
        form.value[field] = ''
        v$.value[field].$touch()
        emits('formError', [resp.errors[errorIndex]])
      }
      else {
        emits('formError', [resp.errors[errorIndex]])
      }
    }
    else {
      emits('formError', resp.errors)
    }
  }
  else {
    // Success means account creation, store progress so they can finish signup here instead of at member site
    localStorage.setItem('cd_ctoken', customerData.value.customerToken)
    localStorage.setItem('cd_ttoken', customerData.value.trackingToken)
    localStorage.setItem('cd_cstep', '3')

    emits('nextStep')
    emits('updateCustomer', {
      ...resp,
      name: `${form.value.firstName} ${form.value.lastName}`,
      firstName: `${form.value.firstName}`,
      lastName: `${form.value.lastName}`,
      street: form.value.street,
      city: form.value?.city ? form.value.city : resp.model['homeAddress.city'],
      state: form.value?.state ? form.value.state : resp.model['homeAddress.state'],
      zip: form.value.zip,
      birthdate: form.value.birthdate,
      phone: form.value.phone,
      fullSsnRequired: fullSsnRequired.value,
    })
  }
}
</script>

<script>
export default {
  name: 'PersonalStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.personal') }}
      </p>
      <InputText
        v-model.trim="form.firstName"
        class="firstname"
        name="firstname"
        :tabindex="1"
        :required="true"
        :label="t('form.firstName')"
        :autofocus="true"
        :v="v$.firstName"
        :t="t"
        @blur="inputBlur('firstName')"
      />
      <InputText
        v-model.trim="form.lastName"
        class="lastname"
        name="lastname"
        :tabindex="3"
        :required="true"
        :label="t('form.lastName')"
        :v="v$.lastName"
        :t="t"
        @blur="inputBlur('lastName')"
      />
      <InputText
        v-model.trim="form.street"
        name="street"
        :tabindex="4"
        :required="true"
        :label="t('form.address')"
        :v="v$.street"
        :t="t"
        @blur="inputBlur('street')"
      />
      <div v-if="showCityState" :class="{ 'cd-form-error': showZipErrorMessage }">
        <p v-if="showZipErrorMessage">
          {{ t('errors.missingZip') }}
        </p>
        <InputText
          v-model.trim="form.city"
          name="city"
          :tabindex="5"
          :required="true"
          :label="t('form.city')"
          :autofocus="true"
          :v="v$.city"
          :t="t"
          @blur="inputBlur('city')"
        />
        <InputSelect
          v-model.trim="form.state"
          name="state"
          :tabindex="6"
          :required="true"
          :label="t('form.state')"
          :optionslist="states"
          :v="v$.state"
          :t="t"
          @blur="inputBlur('state')"
        />
      </div>
      <InputText
        v-model.trim="form.zip"
        name="zip"
        :tabindex="7"
        :required="true"
        :label="t('form.zip')"
        mask="#####"
        :v="v$.zip"
        :t="t"
        @blur="inputBlur('zip')"
      />
      <InputText
        v-model.trim="form.phone"
        name="phone"
        :tabindex="8"
        :required="true"
        :label="t('form.phone')"
        mask="###!-###!-####"
        :v="v$.phone"
        :t="t"
        @blur="inputBlur('phone')"
      />
      <InputText
        v-model.trim="form.birthdate"
        name="birthdate"
        :tabindex="9"
        :required="true"
        :label="t('form.birthDate')"
        mask="##/##/####"
        placeholder="mm/dd/yyyy"
        :v="v$.birthdate"
        :t="t"
        @blur="inputBlur('birthdate')"
      />
      <InputText
        v-if="fullSsnRequired"
        v-model.trim="form.ssn"
        name="ssn"
        :tabindex="10"
        :required="true"
        :label="t('form.ssn')"
        mask="###! ##! ####"
        placeholder="*** ** ****"
        :autofocus="true"
        :v="v$.ssn"
        :t="t"
        :class="{ 'cd-form-error': focusSsn }"
        @blur="inputBlur('ssn')"
        @changed="ssnChange"
      />
      <InputText
        v-else
        v-model.trim="form.ssnPartial"
        type="text"
        name="ssnPartial"
        :tabindex="11"
        :required="true"
        mask="!*!*!* !*!* ####"
        :label="t('form.last4Ssn')"
        :v="v$.ssnPartial"
        :t="t"
        @blur="inputBlur('ssnPartial')"
        @change="resetSsnPartial"
      />
      <template v-if="!customerData?.isConfirmedTerms && isSponsoredPlan">
        <label for="terms"><input id="terms" v-model="form.isConfirmedTerms" type="checkbox" name="terms" tabindex="12"><i18n-t
          keypath="form.confirmTerms"
          tag="span"
          scope="global"
        >
          <template #product>{{ productName }}</template>
          <template #serviceAgreement>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/service-agreement.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.serviceAgreement') }}</a>
          </template>
          <template #terms>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/site-use.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.terms') }}</a>
          </template>
          <template #privacy>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/privacy-policy.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.privacy') }}</a>
          </template>
          <template #rights>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/consumer-rights.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.rights') }}</a>
          </template>
        </i18n-t></label>
      </template>
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="12"
        :disabled="v$.$invalid || processing"
        @click.prevent="updateIdentity()"
      >
        {{ t('form.step2Submit') }}
      </button>
    </div>
  </form>
</template>
