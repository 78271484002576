<script setup>
import { customerData, isSponsoredPlan, offerData } from '@/store'
import { currencyFormatter } from '@/filters'

const i18n = inject('i18n')
const mode = inject('signupMode')

const externalMode = computed(() => mode === 'external')

/**
 * Computed memberplans filter sponsored
 *
 */
const memberPlans = computed(() => {
  const plans = externalMode.value ? offerData.value.memberPlans?.plans : offerData.value.memberPlans?.signupPlans

  // From the billing step onward, only show the selected memberplan
  // If it is a sponsored plan, there is no billing step so we always show it
  if (
    customerData.value.orderComplete
    || customerData.value.stepName === 'BillingStep'
    || customerData.value.planType === 'SPONSORED'
  ) {
    return plans.filter(x => x.planType === customerData.value.planType)
  }
  else {
    return plans
      ? isSponsoredPlan.value
        ? plans.filter(x => x.planType === 'SPONSORED')
        : plans.filter(x => x.planType !== 'SPONSORED')
      : null
  }
})
</script>

<template>
  <div class="cd-widget__container">
    <div class="cd-plan-details">
      <div v-for="plan in memberPlans" :key="plan.planType">
        <p class="cd-plan-details__legend">
          {{ plan.name }} {{ i18n.global.t('offer.membership') }}
        </p>
        <dl>
          <dt>Smart Credit Report<sup>&reg;</sup> &amp; Scores</dt>
          <dd>
            {{ i18n.global.t('offer.monthlyUpdates', plan.premium ? 0 : plan.maxCreditReportsPerMonth) }}
          </dd>
          <dt>ScoreTracker</dt>
          <dd>{{ i18n.global.t('offer.unlimited') }}</dd>
          <dt>ScoreBuilder<sup>&reg;</sup></dt>
          <dd>{{ plan.hasScoreBuilder ? i18n.global.t('offer.unlimited') : '--' }}</dd>
          <dt>ScoreBoost<sup>&trade;</sup></dt>
          <dd>{{ plan.hasScoreMaster ? i18n.global.t('offer.unlimited') : '--' }}</dd>
          <dt>{{ i18n.global.t('offer.actions') }}</dt>
          <dd>
            {{
              plan.hasAction
                ? plan.maxActionsPerMonth > 0
                  ? plan.premium
                    ? i18n.global.t('offer.unlimited')
                    : `${plan.maxActionsPerMonth} ${i18n.global.t('offer.permonth')}`
                  : '--'
                : '--'
            }}
          </dd>
          <dt>{{ i18n.global.t('offer.creditMonitoring') }}</dt>
          <dd>{{ i18n.global.t('offer.unlimited') }}</dd>
          <dt>Money Manager</dt>
          <dd>{{ plan.hasMoneyManager ? i18n.global.t('offer.unlimited') : '--' }}</dd>
          <dt>{{ i18n.global.t('offer.3bReport') }}</dt>
          <dd>
            {{ plan.included3BReportsPerMonth > 0 ? plan.included3BReportsPerMonth : i18n.global.t('offer.alacarte') }}
          </dd>
          <dt>{{ i18n.global.t('offer.subscriptionStart') }}</dt>
          <dd>{{ new Date().toLocaleDateString() }}</dd>
          <dt v-if="plan.isTrial || plan.isBundle">
            {{ i18n.global.t('offer.trialPeriod') }}
          </dt>
          <dd v-if="plan.isTrial">
            {{ i18n.global.t('offer.trialLength', plan.trialLength) }} <template v-if="plan.chargeSetup">
              {{ i18n.global.t('offer.bundleCost', plan.chargeSetup) }}
            </template>
            <template v-else>
              {{ i18n.global.t('offer.trialFree') }}
            </template>
          </dd>
          <dd v-else-if="plan.isBundle">
            {{ i18n.global.t('offer.bundleTerm', plan.packageTerm) }} <template v-if="plan.chargeSetup">
              {{ i18n.global.t('offer.bundleCost', plan.chargeSetup) }}
            </template>
            <template v-else>
              {{ i18n.global.t('offer.trialFree') }}
            </template>
          </dd>
          <dt>{{ i18n.global.t('offer.monthlyFeeStarting') }} {{ plan.firstBillDate }}</dt>
          <dd>
            {{
              plan.offerType === 'SPONSORED'
                ? '$0.00'
                : !plan.isBundle
                  ? currencyFormatter.format(plan.chargeMonthly)
                  : currencyFormatter.format(plan.nextPlan.chargeMonthly)
            }}
            <small v-if="plan.offerType === 'SPONSORED'" class="cd-block">({{ i18n.global.t('offer.planSponsored') }})</small>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
