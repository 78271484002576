<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required, requiredIf } from '@vuelidate/validators'
import { customerData, signupMilestoneName, signupMilestones } from '@/store'
import dVLoaderScript from '@/modules/dVLoader'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'previousStep',
  'dispatchStepEvent',
])

const processing = inject('processing')
const sendSegmentTrack = inject('sendSegmentTrack')

/**
 * DV refs
 * Digital Verification global variable declaration
 */

// Blackbox data that is to be passed to backend, watched by
const dVBlackbox = ref('')
const dvScriptLoaded = ref(false)
const dVCheckFunctionCheckCounter = ref(0)
// maximum times that we will check if Blackbox getter function is set
const dVCheckFunctionCheckMaxInterval = 20

/**
 * getBlackboxCallBack is the DV config callback function
 */
function getBlackboxCallBack(bb, complete) {
  if (!dvScriptLoaded.value)
    dvScriptLoaded.value = true

  if (!dVBlackbox.value) {
    // function will attempt to get a completed blackbox value a maximum of 20 times before throwing an error
    if (dVCheckFunctionCheckCounter.value < dVCheckFunctionCheckMaxInterval) {
      if (complete)
        dVBlackbox.value = bb
      else
        dVCheckFunctionCheckCounter.value++
    }
    else {
      emits('formError', [{ code: 'BLACKBOX_FAILED_LOAD' }])
    }
  }
}

/**
 * Questions ref tracks the current questions returned to answer
 * Each submission requires the reference number supplied with questions
 *
 */
const questions = ref([])

/**
 * OTP refs
 */
const idVerificationCriteria = ref()
const failedPinValidation = ref(false)
const showResetLink = ref(false)

const isIDMAChoice = computed(() => idVerificationCriteria.value?.question1.name === 'IDM_Choice')
const isIDMAPinVerify = computed(() => idVerificationCriteria.value?.question1.name === 'IDM_PINVERIFY')

/*
 * Watch for pinverify step
 */
watch(
  () => isIDMAPinVerify,
  (a) => {
    if (a.value)
      setTimeout(() => showResetLink.value = true, 3000)
  },
  { immediate: true, deep: true },
)

/**
 * Once dVBlackbox is set load questions to populate form
 */
watch(dVBlackbox, () => {
  getVerificationQuestions()
})

/**
 * Form validation
 * form <Object> ref for form inputs
 * error <String> ref for step specific errors
 * rules <Object> vuelidate rules object
 */
const form = ref({
  'answers': [],
  'idVerificationCriteria.userInput': '',
})

const error = ref('')

const rules = {
  'answers': {
    $each: helpers.forEach({
      answer: {
        required,
      },
    }),
  },
  'idVerificationCriteria.userInput': {
    required: helpers.withMessage(
      '',
      requiredIf(() => isIDMAPinVerify.value),
    ),
    minLength: helpers.withMessage(() => props.t('errors.pinInvalid'), minLength(4)),
    maxLength: helpers.withMessage(() => props.t('errors.pinInvalid'), maxLength(8)),
    validChars: helpers.withMessage(() => props.t('errors.pinInvalid'), value => /^[A-Za-z0-9-_]*$/.test(value)),
    validate: helpers.withMessage(() => props.t('errors.sponsorCodeValid'), () => !failedPinValidation.value),
  },
}

const v$ = useVuelidate(rules, form, {})

/**
 * Clear questions and form
 */
function clearForm() {
  questions.value = []
  form.value.answers = []
  form.value['idVerificationCriteria.userInput'] = ''
  showResetLink.value = false
}

/**
 * Load questions into ref
 */
function loadQuestions(idmaQuestions) {
  clearForm()

  for (const key in idmaQuestions) {
    if (key.startsWith('question')) {
      questions.value.push(idmaQuestions[key])
      form.value.answers.push({ answer: '' })
    }
  }
}

/**
 * Form submission
 * Submit answers to ID verification
 * On error reload verification questions
 *
 */
async function verifyIdentity() {
  emits('clearFormError')
  error.value = ''

  const fields = {}

  // Request object needs user input if pin verify step of otp
  if (isIDMAPinVerify.value) {
    fields['idVerificationCriteria.userInput'] = form.value['idVerificationCriteria.userInput']
  }
  else {
  // map reponse object from form ref
    form.value.answers.forEach((answer, index) => {
      fields[`idVerificationCriteria.answer${index + 1}`] = answer.answer
    })
  }

  const resp = await props.signupApi({
    url: '/id-verification',
    method: 'POST',
    fields: {
      'idVerificationCriteria.referenceNumber': idVerificationCriteria.value.referenceNumber,
      'idVerificationCriteria.transunionDigitalVerificationBlackBox': dVBlackbox.value,
      ...fields,
    },
    blocking: true,
  })

  if (resp?.errors) {
    clearForm()

    if (resp.errors[0] === 'EXCEEDED_MAX_TRIES') {
      emits('formError', resp.errors)
    }
    else {
      getVerificationQuestions()
      emits('formError', resp.errors)
    }
  }
  else {
    if (resp.idVerificationCriteria) {
      idVerificationCriteria.value = resp.idVerificationCriteria

      // Check for incorrect pin response by looking for existing userInput after the response
      failedPinValidation.value = form.value['idVerificationCriteria.userInput']
        && resp.idVerificationCriteria.response === 'MORE_QUESTIONS'
        && resp.idVerificationCriteria.question1.name === 'IDM_PINVERIFY'

      if (failedPinValidation.value) {
        form.value['idVerificationCriteria.userInput'] = ''
        return
      }

      // Not a failed pin means more questions
      if (!isIDMAPinVerify.value)
        loadQuestions(resp.idVerificationCriteria)
    }
    else {
      // Successful ID verification, store progress
      localStorage.setItem('cd_cstep', '4')
      sendSegmentTrack(signupMilestoneName, signupMilestones.IDMA_COMPLETED)
      emits('nextStep')
      emits('updateCustomer', resp)
    }
  }
}

/**
 * Get ID verification questions and create question and matching answer arrays for ref
 *
 */
async function getVerificationQuestions() {
  if (processing.value) {
    setTimeout(() => {
      getVerificationQuestions()
    }, 100)
    return
  }

  const resp = await props.signupApi({
    url: '/id-verification',
    fields: {
      customerToken: customerData.value.customerToken,
      trackingToken: customerData.value.trackingToken,
      transunionDigitalVerificationBlackBox: dVBlackbox.value,
    },
    blocking: true,
    signupMilestone: signupMilestones.IDMA_QUESTIONS_PRESENTED,
  })

  if (resp?.errors) {
    if (
      Array.isArray(resp.errors)
      && resp.errors.findIndex(x => x.code === 'ID_ALREADY_VERIFIED') > -1
    ) {
      localStorage.setItem('cd_cstep', '4')
      emits('nextStep')
    }
    else {
      localStorage.setItem('cd_cstep', '2')
      emits('previousStep')
      emits('formError', resp.errors)
    }
  }
  else {
    idVerificationCriteria.value = resp.idVerificationCriteria

    if (!isIDMAPinVerify.value)
      loadQuestions(resp.idVerificationCriteria)

    // Reset form validation
    v$.value.$reset()
  }
}

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()

  if (input === 'idVerificationCriteria.userInput' && failedPinValidation.value)
    failedPinValidation.value = false
}

async function restartIDMA() {
  try {
    const resp = await props.signupApi({
      url: '/id-verification',
      method: 'POST',
      fields: {
        'idVerificationCriteria.referenceNumber': idVerificationCriteria.value.referenceNumber,
        'idVerificationCriteria.userInput': 'RESET',
      },
      blocking: true,
    })

    if (resp?.errors) {
      clearForm()

      localStorage.setItem('cd_cstep', '2')
      emits('previousStep')
      emits('formError', [{ code: 'ID_VERIFICATION_OTP_RESTART' }])
    }
    else {
      emits('formError')
    }
  }
  catch {
    clearForm()

    localStorage.setItem('cd_cstep', '2')
    emits('previousStep')
    emits('formError', [{ code: 'ID_VERIFICATION_OTP_RESTART' }])
  }
}

function dvScriptWatcher() {
  setTimeout(() => {
    // if the script fails to load, throw error
    if (!dvScriptLoaded.value)
      emits('formError', [{ code: 'BLACKBOX_FAILED_LOAD' }])
  }, 1000)
}

/**
 * On component mount
 * Emit step event
 * load DV loader_only script, uses callback function bb_callback to set black box as dVBlackbox ref
 */
onMounted(() => {
  emits('dispatchStepEvent')

  window.io_global_object_name = 'IGLOO'
  window.IGLOO = window.IGLOO || {
    enable_flash: false,
    //  bb_callback sets dVBlackbox value once loader function gathers async data
    bb_callback: getBlackboxCallBack,
    loader: {
      subkey: 'i07bAlexpwOuUoOhI2Z81QL0xKnFrxmVSbWC7Ar-XH4',
      version: 'general5',
    },
  }

  dVLoaderScript()
  dvScriptWatcher()
})
</script>

<script>
export default {
  name: 'IdentityStep',
}
</script>

<template>
  <form v-if="!processing" role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        <template v-if="isIDMAChoice">
          {{ t('headings.identityOtpChoice') }} <small>{{ t('headings.identityOtpChoiceSmall') }}</small>
        </template>
        <template v-else-if="isIDMAPinVerify">
          {{ t('headings.identityOtpVerify') }} <small>{{ t('headings.identityOtpVerifySmall', customerData?.phone?.slice(-4)) }}</small>
        </template>
        <template v-else>
          {{ t('headings.identity') }} <small>{{ t('headings.identitySmall') }}</small>
        </template>
      </p>
      <div v-if="isIDMAPinVerify">
        <InputText
          v-model="form['idVerificationCriteria.userInput']"
          name="idVerificationCriteria.userInput"
          :required="isIDMAPinVerify"
          :label="t('form.verificationCode')"
          :tabindex="1"
          :minlength="4"
          :maxlength="8"
          :v="v$['idVerificationCriteria.userInput']"
          @blur="inputBlur('idVerificationCriteria.userInput')"
        />
      </div>
      <transition-group v-else name="fade">
        <div v-for="(question, index) in questions" :key="question.name" class="cd-radio">
          <label v-if="!isIDMAChoice" :for="`answers${index}`">{{ question.displayName }}</label>
          <div
            v-for="(choice, ind) in question.choiceList.choice"
            :key="ind"
            class="cd-radio__input"
          >
            <label>
              <input
                type="radio"
                :value="choice.key"
                :name="`answers${index}`"
                :checked="false"
                @input="form.answers[index].answer = choice.key"
              >
              <span>{{ choice.display }}</span>
            </label>
          </div>
        </div>
      </transition-group>
    </fieldset>
    <div v-if="questions.length" class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="9"
        :disabled="v$.$invalid || processing"
        @click.prevent="verifyIdentity"
      >
        {{ t('form.step3Submit') }}
      </button>
      <transition name="fade" mode="out-in">
        <p v-if="showResetLink" class="cd-text-primary cd-cursor-pointer" @click="restartIDMA">
          <small>{{ t('form.restartOtp') }}</small>
        </p>
      </transition>
    </div>
  </form>
</template>
