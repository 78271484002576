import { trackingParams } from '@/store'

/**
 * Intake and store all url parameter tracking variables
 * Allows for multiple points to set the funnel pid
 * Prioritizes in this order: (1) url parameter, (2) hidden input[name="pid"]
 *
 * @function
 */
export function useTracking(urlParams: URLSearchParams, externalMode: boolean) {
  const resetTracking = () => {
    trackingParams.value = {}
  }

  const saveTrackingParam = (param: string, val: string): void => {
    trackingParams.value[param] = val
  }

  const getTracking = () => {
    const cd_tokens = ['pid', 'sid', 'tid', 'aid', 'adid', 'sourceid', 'cid']
    const utm_tokens = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

    resetTracking()

    if (!externalMode) {
      // Other tracking - see cd_tokens for reference
      if (Array.from(urlParams.values()).length) {
        // If there are any tracking tokens set in the url, all previously stored tokens are wiped
        cd_tokens.forEach((p) => {
          if (localStorage.getItem(p.toUpperCase()))
            localStorage.removeItem(p.toUpperCase())
        })

        /* Save filtered URL params for tracking tokens to store
        * Defined in cd_tokens and utm_tokens
        */
        urlParams.forEach((p, t) => {
          const token = t.toLowerCase()
          if (cd_tokens.findIndex(x => x === token) !== -1) {
            // Must capitalize CD tokens to be registered in the backend
            saveTrackingParam(token.toUpperCase(), encodeURI(p))

            // Save token to localStorage
            localStorage.setItem(token.toUpperCase(), encodeURI(p))
          }
          // GA tracking tokens
          else if (utm_tokens.findIndex(x => x === token) !== -1) {
            saveTrackingParam(token, encodeURI(p))
          }
        })
      }
      else {
        /* Retrieve any tokens from localStorage
        */
        cd_tokens.forEach((p) => {
          const local = localStorage.getItem(p.toUpperCase())
          if (local)
            saveTrackingParam(p.toUpperCase(), local)
        })
      }
    }
    else {
      cd_tokens.forEach((p) => {
        const input = document.getElementsByName(p.toUpperCase())[0] as HTMLInputElement
        if (input)
          saveTrackingParam(p.toUpperCase(), input.value)
      })
    }

    if (document.getElementsByName('PID').length > 0) {
      const pidInput = document.getElementsByName('PID')[0] as HTMLInputElement
      saveTrackingParam('PID', encodeURI(pidInput.value))
    }

    return {
      ...trackingParams.value,
    }
  }

  return { getTracking }
}
