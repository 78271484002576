<script setup>
import { analytics, segmentLoaded } from '@/modules/segment'
import { customerData, hasTrial, passwordlessLoginLink, signupMilestones } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'dispatchStepEvent'])

const productName = inject('productName')

/**
 * Signup complete api call
 * Loads temp password for quick login, clears localStorage
 */
onMounted(async () => {
  const resp = await props.signupApi({
    url: '/customer/complete',
    method: 'POST',
    fields: {
      isConfirmedTerms: true,
    },
    blocking: true,
    signupMilestone: signupMilestones.SIGNUP_COMPLETED,
  })

  if (resp?.errors) {
    emits('formError', resp.errors[0])
  }
  else {
    customerData.value.orderComplete = true

    if (resp?.passwordlessCode)
      customerData.value.passwordlessCode = resp.passwordlessCode

    emits('dispatchStepEvent')
  }
})

/**
 * Clear segment cookies before navigating away from this step
 */
onUnmounted(() => {
  if (segmentLoaded.value)
    analytics.reset()
})
</script>

<script>
export default {
  name: 'ConfirmationStep',
}
</script>

<template>
  <div v-if="customerData.orderComplete" class="cd-form">
    <fieldset>
      <p class="cd-form__legend cd-mb-5">
        {{ t('headings.welcomeToMembership', { product: productName }) }}
      </p>
      <div class="cd-flex cd-mb-5">
        <div class="cd-flex-1">
          <p>
            <strong>{{ t('form.username') }}</strong><br>{{ customerData.email }}
          </p>
          <p>
            <strong>{{ t('form.membertype') }}</strong><br>
            {{ customerData.planType }}
          </p>
          <a :href="passwordlessLoginLink">{{ t('form.loginNow') }}</a>
        </div>
        <div class="cd-flex-1">
          <p>
            <strong>{{ customerData.name.firstName }} {{ customerData.name.lastName }}</strong>
          </p>
          <p class="cd-mb-5">
            {{ customerData.address?.street }}<br>{{ customerData.address?.city }}, {{ customerData.address?.state }}
            {{ customerData.address?.zip }}
          </p>
        </div>
      </div>
      <div class="cd-mt-4">
        <p>
          <template v-if="hasTrial">
            {{ t('form.hasTrial', { product: productName }) }}
          </template>
        </p>
      </div>
    </fieldset>
  </div>
</template>
