<script setup>
import useVuelidate from '@vuelidate/core'
import {
  helpers,
  maxLength,
  minLength,
  required,
  requiredIf,
  sameAs,
} from '@vuelidate/validators'
import { birthdate, invalidChars, phone, zip } from '@/validation/validators'
import { states } from '@/composables/states-list'
import {
  customerData,
  externalLoginLink,
  isSponsoredPlan,
  presetFirstName,
  presetLastName,
  presetMiddleName,
  signupMilestones,
} from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'dispatchStepEvent',
])

/**
 * Injections
 * - Member url is set by the end user on the mount point as data attributes
 *
 */
const memberSiteUrl = inject('memberSiteUrl')
const processing = inject('processing')
const productName = inject('productname')

onMounted(() => emits('dispatchStepEvent'))

/**
 * Form validation
 * Reactive variables capture possible error responses and their necessary UI changes
 * form <Object> ref for form inputs
 * rules <Object> vuelidate rules object
 */
const showCityState = ref(false)
const fullSsnRequired = ref(
  customerData.value?.fullSsnRequired
    ? customerData.value.fullSsnRequired
    : false,
)
const focusSsn = ref(false)
const ssnErrorMessage = ref('')
const showZipErrorMessage = ref(false)
const ssnReason = ref('')
const ssnUnique = ref(true)
const isMarketingOptIn = ref(false)

const form = ref({
  firstName: presetFirstName.value
    ? presetFirstName.value
    : customerData.value?.name?.firstName
      ? customerData.value.name?.firstName
      : '',
  middleName: presetMiddleName.value
    ? presetMiddleName.value
    : customerData.value?.name?.middleName
      ? customerData.value.name?.middleName
      : '',
  lastName: presetLastName.value
    ? presetLastName.value
    : customerData.value?.name?.lastName
      ? customerData.value.name?.lastName
      : '',
  ssnPartial: '',
  ssn: '',
  birthdate: customerData.value?.birthdate ? customerData.value.birthdate : '',
  street: customerData.value?.address?.street
    ? customerData.value.address.street
    : '',
  city: customerData.value?.address?.city
    ? customerData.value.address.city
    : '',
  state: customerData.value?.address?.state
    ? customerData.value.address.state
    : '',
  zip: customerData.value?.address?.zip ? customerData.value.address.zip : '',
  phone: customerData.value?.phone ? customerData.value.phone : '',
  ...(isSponsoredPlan.value && { isConfirmedTerms: false }),
})

const rules = computed(() => ({
  firstName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(
      () => props.t('errors.invalidChars'),
      invalidChars,
    ),
  },
  middleName: {},
  lastName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(
      () => props.t('errors.invalidChars'),
      invalidChars,
    ),
  },
  ssn: {
    minLength: helpers.withMessage(
      () => props.t('errors.ssnLength'),
      minLength(11),
    ),
    required: helpers.withMessage(
      ssnErrorMessage.value,
      requiredIf(() => fullSsnRequired.value),
    ),
    unique: helpers.withMessage(
      () => ssnReason.value,
      () => ssnUnique.value,
    ),
  },
  ssnPartial: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => !fullSsnRequired.value),
    ),
    minLength: helpers.withMessage(
      () => props.t('errors.ssnPartialLength'),
      minLength(11),
    ),
    maxLength: helpers.withMessage(
      () => props.t('errors.ssnPartialLength'),
      maxLength(11),
    ),
    unique: helpers.withMessage(
      () => ssnReason.value,
      () => ssnUnique.value,
    ),
  },
  birthdate: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    birthdate: helpers.withMessage(
      () => props.t('errors.birthdate'),
      birthdate,
    ),
    minLength: helpers.withMessage(() => props.t('errors.date'), minLength(10)),
    maxLength: helpers.withMessage(() => props.t('errors.date'), maxLength(10)),
  },
  street: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
  },
  city: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => showCityState.value),
    ),
    invalidChars: helpers.withMessage(
      () => props.t('errors.invalidChars'),
      invalidChars,
    ),
  },
  state: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(() => showCityState.value),
    ),
  },
  zip: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    zip: helpers.withMessage(() => props.t('errors.zip'), zip),
  },
  phone: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    phone: helpers.withMessage(() => props.t('errors.phone'), phone),
  },
  ...(isSponsoredPlan.value && {
    isConfirmedTerms: {
      required: helpers.withMessage(
        props.t('errors.confirmTerms'),
        sameAs(true),
      ),
    },
  }),
  isMarketingOptIn: {},
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handles input blur
 * triggers validation on blur, not input
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
}

function ssnChange() {
  ssnUnique.value = true
  focusSsn.value = true
  ssnReason.value = ''
}

/**
 * Form submit
 *
 */
async function updateIdentity() {
  // Reset errors
  emits('clearFormError')
  showZipErrorMessage.value = false
  ssnErrorMessage.value = ''
  focusSsn.value = false

  const birthdate = new Date(form.value.birthdate)

  try {
    const resp = await props.signupApi({
      url: '/customer/identity',
      method: 'POST',
      fields: {
        name: {
          firstName: form.value.firstName,
          middleName: form.value.middleName,
          lastName: form.value.lastName,
        },
        address: {
          street: form.value.street,
          ...(form.value.city && { city: form.value.city }),
          ...(form.value.state && { state: form.value.state }),
          zip: form.value.zip,
        },
        phone: form.value.phone,
        birthDate: birthdate.toISOString().split('T')[0],
        ...(!form.value.ssn && { ssnPartial: form.value.ssnPartial.slice(-4) }),
        ...(form.value.ssn && { ssn: form.value.ssn.replace(/\s/g, '') }),
        ...(isSponsoredPlan.value && {
          isConfirmedTerms: form.value.isConfirmedTerms,
          isBrowserConnection: true,
        }),
        isMarketingOptIn: isMarketingOptIn.value,
        trackingToken: customerData.value.trackingToken,
      },
      blocking: true,
      signupMilestone: signupMilestones.SECURITY_INFO_RECEIVED,
    })

    if (resp?.errors) {
      const errorArr = [
        'SSN_NO_MATCH',
        'FULL_SSN_REQUIRED',
        'SSN_REQUIRED',
        'SSN_USED',
        'INVALID_PARTIAL_SSN',
        'INVALID_SSN',
        'PARTIAL_SSN_REQUIRED',
        'ZIP_LOOKUP_FAILED',
        'FIELD_INVALID_CHARACTERS',
        'FIELD_INVALID',
        'FIELD_REQUIRED',
      ]

      const errorIndex = resp.errors.findIndex(x => errorArr.includes(x.code))
      // Some errors we should update the UI
      if (errorIndex !== -1) {
        const errorCode = resp.errors[errorIndex].code

        if (errorCode === 'ZIP_LOOKUP_FAILED') {
          showCityState.value = true
          showZipErrorMessage.value = true
        }
        else if (errorCode === 'SSN_USED') {
          ssnUnique.value = false
          focusSsn.value = true
          ssnReason.value = resp.errors[0].message.replace(
            'login',
            `<a href="${externalLoginLink.value}">${props.t('form.login')}</a>`,
          )
          v$.value.ssn.$touch()
        }
        else if (
          errorCode === 'SSN_NO_MATCH'
          || errorCode === 'FULL_SSN_REQUIRED'
          || errorCode === 'SSN_REQUIRED'
          || errorCode === 'INVALID_PARTIAL_SSN'
        ) {
          fullSsnRequired.value = true
          focusSsn.value = true
          emits('formError', [{ code: 'fullSsnRequired' }])
        }
        else if (errorCode === 'PARTIAL_SSN_REQUIRED') {
          fullSsnRequired.value = false
        }
        else if (
          errorCode === 'FIELD_INVALID_CHARACTERS'
          || errorCode === 'FIELD_REQUIRED'
          || errorCode === 'FIELD_INVALID'
        ) {
          const field = resp.errors[errorIndex].field.split('.').pop()
          form.value[field] = ''
          v$.value[field].$touch()
          emits('formError', [resp.errors[errorIndex]])
        }
        else {
          emits('formError', [resp.errors[errorIndex]])
        }
      }
      else {
        emits('formError', resp.errors)
      }
    }
    else {
      emits('nextStep')
      emits('updateCustomer', {
        ...resp,
        name: {
          firstName: form.value.firstName,
          middleName: form.value.middleName,
          lastName: form.value.lastName,
        },
        address: {
          street: form.value.street,
          city: form.value?.city ? form.value.city : resp.city,
          state: form.value?.state ? form.value.state : resp.state,
          zip: form.value.zip,
        },
        birthdate: form.value.birthdate,
        phone: form.value.phone,
        fullSsnRequired: fullSsnRequired.value,
        isConfirmedTerms: form.value.isConfirmedTerms,
      })
    }
  }
  catch {
    emits('formError')
  }
}
</script>

<script>
export default {
  name: 'PersonalStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p v-if="fullSsnRequired" class="cd-form__legend">
        {{ t('headings.personalFullSsn') }}
        <small>{{ t('form.inputYourFullSsn') }}</small>
      </p>
      <p v-else class="cd-form__legend">
        {{ t('headings.personal') }}
      </p>
      <div class="cd-form__input-group">
        <InputText
          v-model.trim="form.firstName"
          class="firstname cd-flex-1"
          name="firstname"
          :tabindex="1"
          :required="true"
          :label="t('form.firstName')"
          :v="v$.firstName"
          :t="t"
          @blur="inputBlur('firstName')"
        />
        <InputText
          v-model.trim="form.middleName"
          class="middlename cd-flex-1"
          name="middlename"
          :tabindex="2"
          :required="true"
          :label="t('form.middleName')"
          :v="v$.middleName"
          :t="t"
          @blur="inputBlur('middleName')"
        />
      </div>
      <InputText
        v-model.trim="form.lastName"
        class="lastname"
        name="lastname"
        :tabindex="3"
        :required="true"
        :label="t('form.lastName')"
        :v="v$.lastName"
        :t="t"
        @blur="inputBlur('lastName')"
      />
      <template v-if="fullSsnRequired">
        <InputText
          v-model.trim="form.ssn"
          name="ssn"
          :tabindex="4"
          :required="true"
          :label="t('form.ssn')"
          mask="###! ##! ####"
          placeholder="*** ** ****"
          :autofocus="true"
          autocomplete="off"
          :v="v$.ssn"
          :t="t"
          :class="{ 'cd-form-error': focusSsn }"
          @blur="inputBlur('ssn')"
          @changed="ssnChange"
        />
        <InputText
          v-model.trim="form.birthdate"
          name="birthdate"
          :tabindex="5"
          :required="true"
          :label="t('form.birthDate')"
          mask="##/##/####"
          placeholder="mm/dd/yyyy"
          :v="v$.birthdate"
          :t="t"
          @blur="inputBlur('birthdate')"
        />
      </template>
      <div v-else class="cd-form__input-group">
        <InputText
          v-model.trim="form.ssnPartial"
          class="cd-flex-1"
          name="ssnPartial"
          :tabindex="4"
          :required="true"
          mask="!*!*!* !*!* ####"
          :autofocus="true"
          :label="t('form.last4Ssn')"
          :v="v$.ssnPartial"
          :t="t"
          @blur="inputBlur('ssnPartial')"
          @changed="ssnChange"
        />
        <InputText
          v-model.trim="form.birthdate"
          class="cd-flex-1"
          name="birthdate"
          :tabindex="5"
          :required="true"
          :label="t('form.birthDate')"
          mask="##/##/####"
          placeholder="mm/dd/yyyy"
          :v="v$.birthdate"
          :t="t"
          @blur="inputBlur('birthdate')"
        />
      </div>
      <InputText
        v-model.trim="form.street"
        name="street"
        :tabindex="6"
        :required="true"
        :label="t('form.address')"
        :v="v$.street"
        :t="t"
        @blur="inputBlur('street')"
      />
      <div
        v-if="showCityState"
        :class="{ 'cd-form-error': showZipErrorMessage }"
      >
        <p v-if="showZipErrorMessage">
          {{ t('errors.missingZip') }}
        </p>
        <InputText
          v-model.trim="form.city"
          name="city"
          :tabindex="7"
          :required="true"
          :label="t('form.city')"
          :autofocus="true"
          :v="v$.city"
          :t="t"
          @blur="inputBlur('city')"
        />
        <InputSelect
          v-model.trim="form.state"
          name="state"
          :tabindex="8"
          :required="true"
          :label="t('form.state')"
          :optionslist="states"
          :v="v$.state"
          :t="t"
          @blur="inputBlur('state')"
        />
      </div>
      <div class="cd-form__input-group">
        <InputText
          v-model.trim="form.zip"
          name="zip"
          class="cd-flex-1"
          :tabindex="9"
          :required="true"
          :label="t('form.zip')"
          mask="#####"
          :v="v$.zip"
          :t="t"
          @blur="inputBlur('zip')"
        />
        <InputText
          v-model.trim="form.phone"
          name="phone"
          class="cd-flex-1"
          :tabindex="10"
          :required="true"
          :label="t('form.phone')"
          mask="###!-###!-####"
          :v="v$.phone"
          :t="t"
          @blur="inputBlur('phone')"
        />
      </div>
      <InputCheckbox
        v-model="isMarketingOptIn"
        name="isMarketingOptIn"
        :v="v$.isMarketingOptIn"
      >
        <i18n-t keypath="form.marketingTerms" scope="global">
          <template #product>
            {{ productName }}
          </template>
          <template #privacy>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/privacy-policy.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.privacy') }}</a>
          </template>
          <template #serviceAgreement>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/service-agreement.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.serviceAgreement') }}</a>
          </template>
        </i18n-t>
      </InputCheckbox>
      <template v-if="!customerData?.isConfirmedTerms && isSponsoredPlan">
        <InputCheckbox
          v-model="form.isConfirmedTerms"
          name="isConfirmedTerms"
          :v="v$.isConfirmedTerms"
        >
          <i18n-t
            keypath="form.confirmTerms"
            tag="span"
            scope="global"
          >
            <template #product>
              {{ productName }}
            </template>
            <template #serviceAgreement>
              <a
                :href="`${memberSiteUrl}/help/terms-and-privacy/service-agreement.htm?PID=${customerData.pid}`"
                target="_blank"
              >{{ t('form.serviceAgreement') }}</a>
            </template>
            <template #terms>
              <a
                :href="`${memberSiteUrl}/help/terms-and-privacy/site-use.htm?PID=${customerData.pid}`"
                target="_blank"
              >{{ t('form.terms') }}</a>
            </template>
            <template #privacy>
              <a
                :href="`${memberSiteUrl}/help/terms-and-privacy/privacy-policy.htm?PID=${customerData.pid}`"
                target="_blank"
              >{{ t('form.privacy') }}</a>
            </template>
            <template #rights>
              <a
                :href="`${memberSiteUrl}/help/terms-and-privacy/consumer-rights.htm?PID=${customerData.pid}`"
                target="_blank"
              >{{ t('form.rights') }}</a>
            </template>
          </i18n-t>
        </InputCheckbox>
      </template>
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="12"
        :disabled="v$.$invalid || processing"
        @click.prevent="updateIdentity()"
      >
        {{ t('form.step2Submit') }}
      </button>
    </div>
  </form>
</template>
