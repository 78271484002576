<script setup>
const props = defineProps({
  score: {
    type: Number,
  },
})

const i18n = inject('i18n')

const strengthMappings = [
  { class: 'cd-strength-meter__label--poor', text: 'poor' }, // xcvbn password score == 0
  { class: 'cd-strength-meter__label--poor', text: 'poor' }, // xcvbn password score == 1
  { class: 'cd-strength-meter__label--weak', text: 'weak' }, // xcvbn password score == 2
  { class: 'cd-strength-meter__label--fair', text: 'fair' }, // xcvbn password score == 3
  { class: 'cd-strength-meter__label--good', text: 'strong' }, // xcvbn password score == 4
]

const mappingIndex = computed(() =>
  props.score > strengthMappings.length - 1 ? strengthMappings.length - 1 : props.score,
)

const strengthWidth = computed(() => (props.score === 0 ? 25 : (props.score / 4) * 100))

const bgClass = computed(() => (props.score !== undefined ? strengthMappings[mappingIndex.value].class : ''))

const strengthText = computed(() => (props.score !== undefined ? strengthMappings[mappingIndex.value].text : ''))
</script>

<template>
  <div class="cd-strength-meter">
    <div class="cd-strength-meter__score" :class="bgClass" :style="{ width: `${strengthWidth}%` }" />
  </div>
  <span v-if="strengthText" class="cd-strength-meter__label" :class="bgClass"><strong>{{ i18n.global.t('form.strength') }}:</strong> {{ i18n.global.t(`form.${strengthText}`) }}</span>
</template>
