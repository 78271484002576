<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf, sameAs } from '@vuelidate/validators'
import { creditCard, expirationDate, invalidChars, zip } from '@/validation/validators'
import { states } from '@/composables/states-list'
import { customerData, hasSponsoredPlan, isSponsoredPlan, offerData, signupMilestoneName, signupMilestones, trackingParams } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'dispatchStepEvent',
])

/**
 * Injections
 *
 */
const memberSiteUrl = inject('memberSiteUrl')
const processing = inject('processing')
const productName = inject('productname')
const sendSegmentTrack = inject('sendSegmentTrack')

const sameBillingAddress = ref(true)
const showCityState = ref(false)
const zipErrorMessage = ref('')
const premiumPlan = ref(true)
const numberError = ref('')
const numberInvalid = ref(false)
const openSponsorModal = ref(false)

const premiumPlanAvailable = computed(() => offerData.value.premiumPlanAvailable)
const signupPlan = computed(() => {
  if (premiumPlanAvailable.value)
    return premiumPlan.value ? 'PREMIUM' : offerData.value.memberPlans.signupPlanTypes[0]
  else
    return offerData.value.memberPlans.signupPlanTypes[0]
})

watch(
  () => premiumPlan.value,
  () => {
    customerData.value.planType = signupPlan.value
    emits('dispatchStepEvent')
  },
  { immediate: true },
)

/**
 * Form validation
 * form <Object> ref for form inputs
 * mask <Object> ref for dynamic ccNumber masking
 * rules <Object> vuelidate rules object
 */
const form = ref({
  ccName: customerData.value?.name ? customerData.value?.name : '',
  ccNumber: '',
  ccExp: '',
  ccCvv: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  sponsorCodeString: customerData.value?.sponsorCodeString
    ? customerData.value.sponsorCodeString
    : '',
  isConfirmedTerms: customerData.value?.isConfirmedTerms
    ? customerData.value.isConfirmedTerms
    : false,
})

const ccNumberMask = ref('#### #### #### ####')

const rules = computed(() => ({
  ccName: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  ccNumber: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    creditCard: helpers.withMessage(() => props.t('errors.creditCard'), creditCard),
    invalid: helpers.withMessage(numberError.value, requiredIf(numberInvalid.value)),
  },
  ccExp: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    ...(!isSponsoredPlan.value && {
      expirationDate: helpers.withMessage(
        () => props.t('errors.expirationDate'),
        expirationDate,
      ),
    }),
  },
  ccCvv: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
  },
  street: {
    required: requiredIf(
      !isSponsoredPlan.value && (!sameBillingAddress.value || !customerData.value?.street),
    ),
  },
  city: {
    required: requiredIf(showCityState.value),
  },
  state: {
    required: requiredIf(showCityState.value),
  },
  zip: {
    required: requiredIf(
      !isSponsoredPlan.value && (!sameBillingAddress.value || !customerData.value?.zip),
    ),
    ...(!isSponsoredPlan.value
      && (!sameBillingAddress.value || !customerData.value?.zip) && {
      zip: helpers.withMessage(() => props.t('errors.zip'), zip),
    }),
  },
  isConfirmedTerms: {
    required: sameAs(true),
  },
  sponsorCodeString: {},
}))

const v$ = useVuelidate(rules, form, {})

/**
 * computed card type returns card type string
 * card type watcher updates ccNumber mask
 */
const cardType = computed(() => {
  const num = form.value.ccNumber.replace(/\s/g, '')

  if (/^5[1-5]/.test(num))
    return 'Mastercard'
  if (num.startsWith('4'))
    return 'Visa'
  if (/^3(?:4|7)/.test(num))
    return 'AMEX'
  if (/^6(?:011|5|4[4-9]|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d\d|9(?:[01]\d|2[0-5])))/.test(num))
    return 'Discover'
  return null
})

watch(
  () => cardType,
  (type) => {
    ccNumberMask.value = type.value === 'AMEX' ? '#### ###### #####' : '#### #### #### ####'
  },
  { immediate: true, deep: true },
)

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
}

async function submitOrder() {
  emits('clearFormError')
  zipErrorMessage.value = ''
  numberInvalid.value = false

  if (isSponsoredPlan.value) {
    const resp = await props.signupApi({
      url: '/customer/update/sponsor-code',
      method: 'POST',
      fields: {
        sponsorCodeString: customerData.value.sponsorCodeString,
        isConfirmedTerms: customerData.value?.isConfirmedTerms
          ? customerData.value.isConfirmedTerms
          : form.value.isConfirmedTerms,
        isBrowserConnection: true,
      },
      blocking: true,
    })

    if (resp?.errors) {
      customerData.value.sponsorCodeString = ''
      emits('formError', resp.errors)
    }
    else {
      emits('updateCustomer', {
        ...resp,
      })
      emits('nextStep')
    }
  }
  else {
    const cardValidation = await props.signupApi({
      url: '/validate/credit-card-number',
      method: 'POST',
      fields: {
        number: form.value.ccNumber.replace(/ /g, ''),
      },
      blocking: true,
    })

    if (cardValidation?.errors) {
      numberInvalid.value = true
      emits('formError', cardValidation.errors)
    }
    else {
      sendSegmentTrack(signupMilestoneName, signupMilestones.CREDIT_CARD_STARTED)

      const resp = await props.signupApi({
        url: '/customer/update/credit-card',
        method: 'POST',
        fields: {
          'creditCard.name': form.value.ccName,
          'creditCard.token': cardValidation.creditCardToken,
          'creditCard.cvv': form.value.ccCvv,
          'creditCard.expirationMonth': form.value.ccExp.slice(0, 2),
          'creditCard.expirationYear': `20${form.value.ccExp.slice(-2)}`,
          'creditCard.address':
            (sameBillingAddress.value && customerData.value?.street)
              ? customerData.value.street
              : form.value.street,
          ...(showCityState.value && {
            'creditCard.city':
              (sameBillingAddress.value && customerData.value?.city)
                ? customerData.value.city
                : form.value.city,
          }),
          ...(showCityState.value && {
            'creditCard.state':
              (sameBillingAddress.value && customerData.value?.state)
                ? customerData.value.state
                : form.value.state,
          }),
          'creditCard.zip':
            (sameBillingAddress.value && customerData.value?.zip)
              ? customerData.value.zip
              : form.value.zip,
          'planType': signupPlan.value,
          'PID': trackingParams.value.PID,
          'isConfirmedTerms': customerData.value?.isConfirmedTerms
            ? customerData.value.isConfirmedTerms
            : form.value.isConfirmedTerms,
          'isBrowserConnection': true,
        },
        blocking: true,
      })

      sendSegmentTrack(signupMilestoneName, signupMilestones.CREDIT_CARD_ATTEMPT)

      if (resp?.errors) {
        const errorArr = ['ZIP_LOOKUP_FAILED', 'FIELD_INVALID_CHARACTERS']

        const errorIndex = resp.errors.findIndex(x => errorArr.includes(x.code))

        // Some errors we can help fix
        if (errorIndex !== -1) {
          const errorCode = resp.errors[errorIndex].code

          if (errorCode === 'ZIP_LOOKUP_FAILED') {
            showCityState.value = true
            zipErrorMessage.value = props.t('errors.missingZip')
          }
          else {
            emits('formError', resp.errors[errorIndex])
          }
        }
        else {
          emits('formError', resp.errors)
        }
      }
      else {
        emits('updateCustomer', {
          ...resp,
        })
        emits('nextStep')
      }
    }
  }
}
</script>

<script>
export default {
  name: 'BillingStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.billing') }}
        <span
          v-if="hasSponsoredPlan && !isSponsoredPlan"
          class="cd-form__legend-link"
          @click="openSponsorModal = true"
        >
          {{ t('form.sponsorcodePrompt') }}
        </span>
      </p>
      <transition name="fade">
        <SponsorCodeModal
          v-if="openSponsorModal"
          :v="v$.sponsorCodeString"
          :sponsor-code-string="form.sponsorCodeString"
          :signup-api="signupApi"
          @close-modal="openSponsorModal = false"
          @update-customer="emits('updateCustomer', $event)"
        />
      </transition>
      <template v-if="!isSponsoredPlan">
        <InputText
          v-model.trim="form.ccName"
          name="ccName"
          :tabindex="1"
          :required="true"
          :label="t('form.ccName')"
          :v="v$.ccName"
          :t="t"
          @blur="inputBlur('ccName')"
        />
        <InputText
          v-model.trim="form.ccNumber"
          name="ccNumber"
          :tabindex="2"
          :required="true"
          :autofocus="true"
          :label="t('form.ccNumber')"
          :mask="ccNumberMask"
          :v="v$.ccNumber"
          :t="t"
          @blur="inputBlur('ccNumber')"
        >
          <CreditCardIcons :card-type="cardType" />
        </InputText>
        <div class="cd-form__input-group">
          <InputText
            v-model.trim="form.ccExp"
            name="ccExp"
            :tabindex="3"
            :required="true"
            :label="t('form.expDate')"
            mask="##/##"
            placeholder="MM/YY"
            :v="v$.ccExp"
            :t="t"
            @blur="inputBlur('ccExp')"
          />
          <InputText
            v-model.trim="form.ccCvv"
            name="ccCvv"
            :tabindex="4"
            :required="true"
            :label="t('form.cvv')"
            :mask="['###', '####']"
            :v="v$.ccCvv"
            :t="t"
            @blur="inputBlur('ccCvv')"
          />
        </div>
        <label
          v-if="customerData?.zip && customerData?.street"
          for="billingAddress"
          class="cd-mb-3"
        >
          <input
            id="billingAddress"
            v-model="sameBillingAddress"
            type="checkbox"
            name="billingAddress"
            checked="checked"
          >
          {{ t('form.billingSameAs') }}
        </label>
        <div v-if="!sameBillingAddress || !customerData?.zip || !customerData?.street">
          <InputText
            v-model.trim="form.street"
            name="street"
            :tabindex="4"
            :required="true"
            :label="t('form.billingAddress')"
            :v="v$.street"
            :t="t"
            @blur="inputBlur('street')"
          />
          <div v-if="showCityState" class="highlight">
            <p class="formError" v-html="zipErrorMessage" />
            <InputText
              v-model.trim="form.city"
              name="city"
              :tabindex="5"
              :required="true"
              :label="t('form.city')"
              :v="v$.city"
              :t="t"
              @blur="inputBlur('city')"
            />
            <InputSelect
              v-model.trim="form.state"
              name="state"
              :tabindex="6"
              :required="true"
              :label="t('form.state')"
              :optionslist="states"
              :v="v$.state"
              :t="t"
              @blur="inputBlur('state')"
            />
          </div>
          <InputText
            v-model.trim="form.zip"
            name="zip"
            :tabindex="7"
            :required="true"
            :label="t('form.billingZip')"
            mask="#####"
            :v="v$.zip"
            :t="t"
            @blur="inputBlur('zip')"
          />
        </div>
        <label v-if="premiumPlanAvailable" for="premiumPlan" class="cd-mb-3">
          <input id="premiumPlan" v-model="premiumPlan" type="checkbox" name="premiumPlan">
          {{ t('form.premiumPlan') }}
        </label>
      </template>
      <template v-if="!customerData?.isConfirmedTerms">
        <label for="terms" class="cd-mb-3"><input id="terms" v-model="form.isConfirmedTerms" type="checkbox" name="terms"><i18n-t
          keypath="form.confirmTerms"
          tag="span"
          scope="global"
        >
          <template #product>{{ productName }}</template>
          <template #serviceAgreement>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/service-agreement.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.serviceAgreement') }}</a>
          </template>
          <template #terms>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/site-use.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.terms') }}</a>
          </template>
          <template #privacy>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/privacy-policy.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.privacy') }}</a>
          </template>
          <template #rights>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/consumer-rights.htm?PID=${customerData.PID}`"
              target="_blank"
            >{{ t('form.rights') }}</a>
          </template>
        </i18n-t></label>
      </template>
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        class="cd-btn"
        type="submit"
        tabindex="9"
        :disabled="v$.$invalid || processing"
        @click.prevent="submitOrder()"
      >
        {{ t('form.step4Submit') }}
      </button>
    </div>
  </form>
</template>
