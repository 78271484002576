<script setup>
import '@/styles/signup.css'

import {
  cobrandData,
  customerData,
  hasSponsoredPlan,
  isSponsoredPlan,
  offerData,
  presetEmail,
  presetFirstName,
  presetLastName,
  presetMiddleName,
  signupMilestoneName,
  signupMilestones,
  trackingParams,
} from '@/store'

import { useTracking } from '@/composables/useTracking'
import { analytics, getAnonymousId, installSegment, segmentLoaded } from '@/modules/segment'
import { captureException, captureMessage } from '@/modules/sentry'

// Steps components
import AccountStep from '@/components/signup/steps/AccountStep.vue'
import EmailVerificationStep from '@/components/signup/steps/EmailVerificationStep.vue'
import SecurityQuestionsStep from '@/components/signup/steps/SecurityQuestionsStep.vue'
import PersonalStep from '@/components/signup/steps/PersonalStep.vue'
import IdentityStep from '@/components/signup/steps/IdentityStep.vue'
import BillingStep from '@/components/signup/steps/BillingStep.vue'
import ConfirmationStep from '@/components/signup/steps/ConfirmationStep.vue'

// Passwordless Signup components
import PWAccountStep from '@/components/signup/steps/passwordless/PWAccountStep.vue'
import PWPersonalStep from '@/components/signup/steps/passwordless/PWPersonalStep.vue'
import PWIdentityStep from '@/components/signup/steps/passwordless/PWIdentityStep.vue'
import PWBillingStep from '@/components/signup/steps/passwordless/PWBillingStep.vue'
import PWConfirmationStep from '@/components/signup/steps/passwordless/PWConfirmationStep.vue'

const steps = [
  AccountStep,
  EmailVerificationStep,
  SecurityQuestionsStep,
  PersonalStep,
  IdentityStep,
  BillingStep,
  ConfirmationStep,
]
const PWsteps = [
  PWAccountStep,
  EmailVerificationStep,
  PWPersonalStep,
  PWIdentityStep,
  PWBillingStep,
  PWConfirmationStep,
]
const stepNameRef = {
  ACCOUNT: 'AccountStep',
  EMAIL_VERIFICATION: 'EmailVerificationStep',
  IDENTITY: 'PersonalStep',
  ID_VERIFICATION: 'IdentityStep',
  BILLING: 'BillingStep',
  COMPLETE: 'ConfirmationStep',
}

/**
 * i18N instantiation
 */
const { locale, t } = useI18n({
  useScope: 'global',
})

/**
 * Reactive variables
 *
 */
const loading = ref(true)
const processing = ref(false)
const formError = ref('')
const memberUrl = computed(
  () => document.getElementById('cd-signup-widget').dataset.memberurl,
)
const registrantOtc = ref()

let sponsorcodeInput = ''
const sponsorcodeLookupFailed = ref(false)
provide('sponsorcodeLookupFailed', sponsorcodeLookupFailed)

// TODO need to determine when signup can be restarted
const restartAllowed = computed(() => false)

/**
 * Injections
 * - Client key, product name, and member url are set by the end user on the mount point as data attributes, here they are injected for use in the app
 *
 */
const clientKey = inject('clientkey')
const productName = inject('productname')
const productCode = inject('productcode')
const changeClientkey = inject('changeClientkey')
const changeProductName = inject('changeProductName')
const switcherAllowed = inject('switcherAllowed')
const mode = inject('signupMode')
const securityQuestionsEnabled = inject('securityQuestionsEnabled')
const supportingWidgets = inject('supportingWidgets')

/**
 * Computed properties
 * Signup mode determines steps and endpoints
 * data-mode="external" will serve passwordless signup steps
 * and facilitates fusionauth/wapi dance
 */
const externalMode = computed(() => mode.value === 'external')
const supportlinkSignup = computed(
  () =>
    document.getElementById('userdetails')
    && JSON.parse(document.getElementById('userdetails').textContent).userId,
)
function apiEndpoint(endpoint) {
  if (externalMode.value)
    return `${import.meta.env.VITE_WAPI_SERVER}/v1/signup${endpoint}`

  return document.getElementById('cd-signup-widget').dataset.sapiEndpoint
    ? `${document.getElementById('cd-signup-widget').dataset.sapiEndpoint}${endpoint}`
    : `${import.meta.env.VITE_CD_API_SERVER}${endpoint}`
}
const apiContentType = computed(() =>
  externalMode.value ? 'application/json' : 'application/x-www-form-urlencoded',
)
const computedSteps = computed(() => (externalMode.value ? PWsteps : steps))

const availableSignupPlans = computed(() => offerData.value.memberPlans?.plans || offerData.value.memberPlans?.signupPlans)
const memberPlan = computed(() => availableSignupPlans.value.find(x => x.planType === customerData.value.planType))

/**
 * Provides for child component use
 *
 */
provide('memberSiteUrl', memberUrl.value)
provide('processing', processing)
provide('productName', productName)
provide('externalMode', externalMode)
provide('supportlinkSignup', supportlinkSignup)
provide('sendSegmentTrack', sendSegmentTrack)

/**
 * URL inspection vars for tracking tokens and pid
 * - Accepted url tracking tokens in arrays for easy lookup
 *
 */
const urlParams = new URLSearchParams(window.location.search)

/**
 * Steps
 * tokens and progress are saved to localStorage after identity and id verification steps
 *
 */
const customerToken = externalMode.value
  ? null
  : localStorage.getItem('cd_ctoken')
const trackingToken = localStorage.getItem('cd_ttoken')
const currentStep = ref(localStorage.getItem('cd_cstep'))

/**
 * Returns an object constructed for segment properties
 *
 * @computed
 */
const segmentTrackingData = computed(() => {
  return {
    ...(cobrandData.value.company && { company: cobrandData.value.company }),
    product_code: productCode.value ? productCode.value : 'widget',
    publisher_idn: trackingParams.value.PID,
    language: locale.value,
    version: import.meta.env.npm_package_version,
    language_switcher: switcherAllowed.value,
    embedded_sponsorcode: sponsorcodeInput,
    supporting_widgets: Object.keys(supportingWidgets).filter(x => supportingWidgets[x]),
    plan_type: customerData.value.planType,
    ...(productName.value && { product_name: productName.value }),
    ...(clientKey.value && { client_key: clientKey.value }),
    ...(trackingParams.value?.AID && {
      affiliate_id: trackingParams.value.AID,
    }),
    ...(trackingParams.value?.ADID && {
      ad_creative_id: trackingParams.value.ADID,
    }),
    ...(trackingParams.value?.CID && { campaign_id: trackingParams.value.CID }),
    ...(trackingParams.value?.SID && { site_id: trackingParams.value.SID }),
    ...(trackingParams.value?.TID && { template_id: trackingParams.value.TID }),
    ...(trackingParams.value?.utm_source && {
      source: trackingParams.value.utm_source,
    }),
    ...(trackingParams.value?.utm_medium && {
      medium: trackingParams.value.utm_medium,
    }),
    ...(trackingParams.value?.utm_campaign && {
      name: trackingParams.value.utm_campaign,
    }),
    ...(trackingParams.value?.utm_term && {
      term: trackingParams.value.utm_term,
    }),
    ...(trackingParams.value?.utm_content && {
      content: trackingParams.value.utm_content,
    }),
  }
})

function segmentSignupMilestoneData(milestone) {
  return {
    action_source: 'system_generated',
    member_plan_id: memberPlan.value.memberPlanId.toString(),
    milestone: signupMilestones[milestone],
    offer_type: memberPlan.value.isBundle ? 'Bundle' : 'Standard',
    package_type: customerData.value.planType,
    product_code: productCode.value ? productCode.value : 'widget',
    publisher_idn: trackingParams.value.PID,
    ...(cobrandData.value.company && { company: cobrandData.value.company }),
    ...(trackingParams.value?.AID && {
      affiliate_idn: trackingParams.value.AID,
    }),
    ...(trackingParams.value?.ADID && {
      ad_creative_idn: trackingParams.value.ADID,
    }),
    ...(trackingParams.value?.CID && { campaign_idn: trackingParams.value.CID }),
    ...(trackingParams.value?.SID && { site_idn: trackingParams.value.SID }),
  }
}

/**
 * Browser event setup
 * Setup custom event that is triggered for each step change
 * This will allow sites implementing the widget to listen for step changes
 *
 */
function nextStepEvent(step) {
  return new CustomEvent('cd-signup-next-step', {
    detail: {
      step,
      ...(customerData.value.customerToken && {
        customerToken: customerData.value.customerToken,
      }),
      ...(customerData.value.name && { customerName: customerData.value.name }),
      ...(customerData.value.email && {
        customerEmail: customerData.value.email,
      }),
      ...(trackingParams.value.PID && { PID: trackingParams.value.PID }),
      ...(cobrandData.value.company && { company: cobrandData.value.company }),
      ...(customerData.value.passwordlessCode && {
        passwordlessCode: customerData.value.passwordlessCode,
      }),
      ...(customerData.value.planType && {
        memberPlan: customerData.value.planType,
      }),
    },
  })
}

/**
 * App Methods
 */

/**
 * Look for url parameters used to prepopulate form fields
 * Fields:
 *  firstName
 *  lastName
 *  email
 *  sponsorCodeString
 *
 * @function
 */
function prepopFields() {
  if (urlParams.get('firstName'))
    presetFirstName.value = encodeURI(urlParams.get('firstName'))
  if (urlParams.get('middleName'))
    presetMiddleName.value = encodeURI(urlParams.get('middleName'))
  if (urlParams.get('lastName'))
    presetLastName.value = encodeURI(urlParams.get('lastName'))
  if (urlParams.get('email'))
    presetEmail.value = encodeURI(urlParams.get('email'))
}

function dispatchStepEvent() {
  document.dispatchEvent(nextStepEvent(computedSteps.value[currentStep.value].name))
}

/**
 * Segment calls: Page, Track, Identify
 */

function sendSegmentPage() {
  if (segmentLoaded.value) {
    analytics.page(computedSteps.value[currentStep.value].name, {
      ...segmentTrackingData.value,
    })
  }
}

function sendSegmentTrack(event, milestone) {
  if (!event) {
    captureMessage('Segment track called without an event', 'warning')
    return
  }

  if (event === 'Signup Milestone Recorded' && !milestone) {
    captureMessage('Signup Milestone Recorded track called without a milestone', 'warning')
    return
  }

  const data = milestone ? segmentSignupMilestoneData(milestone) : {}

  if (segmentLoaded.value) {
    analytics.track(event, {
      ...(!!data && { ...data }),
    })

    if (milestone === signupMilestones.SIGNUP_COMPLETED) {
      analytics.track('Signed Up', {
        action_source: 'system_generated',
        ...(trackingParams.value?.ADID && {
          ad_creative_idn: trackingParams.value.ADID,
        }),
        ...(trackingParams.value?.AID && {
          affiliate_idn: trackingParams.value.AID,
        }),
        billing_delay: memberPlan.value.trialLength,
        birth_year: Number.parseInt(customerData.value.birthdate?.slice(-4)),
        ...(trackingParams.value?.CID && { campaign_idn: trackingParams.value.CID }),
        charge_monthly: memberPlan.value.chargeMonthly,
        city: externalMode.value ? customerData.value.address.city : customerData.value.city,
        company: cobrandData.value?.company ? cobrandData.value.company : productName.value,
        email: customerData.value.email,
        ...(externalMode.value && { first_bill_date: memberPlan.value.firstBillDate }),
        first_name: externalMode.value ? customerData.value.name.firstName : customerData.value.firstName,
        last_name: externalMode.value ? customerData.value.name.lastName : customerData.value.lastName,
        member_plan_name: memberPlan.value.name,
        offer_type: memberPlan.value.isBundle ? 'Bundle' : 'Standard',
        package_type: customerData.value.planType,
        phone: customerData.value.phone,
        product_code: productCode.value ? productCode.value : 'widget',
        publisher_idn: trackingParams.value.PID,
        ...(trackingParams.value?.SID && { site_idn: trackingParams.value.SID }),
        state: externalMode.value ? customerData.value.address.state : customerData.value.state,
        three_bureau_report_cost: memberPlan.value.charge3B,
        three_bureau_reports_available: memberPlan.value.included3BReportsPerMonth,
        zip_code: externalMode.value ? customerData.value.address.zip : customerData.value.zip,

      })
    }
  }
}

function sendSegmentIdentify() {
  if (segmentLoaded.value && customerData.value.customerToken)
    analytics.alias(customerData.value.customerToken, segmentTrackingData.value)
}

/**
 * Handle previous step logic
 *
 * @function
 */
function previousStep() {
  formError.value = false
  --currentStep.value
}

/**
 * Handle next step logic
 *
 * @function
 */
function nextStep() {
  formError.value = false

  if (currentStep.value === 0) {
    if (customerData.value.isAccountVerificationRequired)
      ++currentStep.value
    else if (securityQuestionsEnabled.value || externalMode.value)
      currentStep.value += 2
    else
      currentStep.value += 3
  }
  else if (currentStep.value === 1 && !securityQuestionsEnabled.value) {
    currentStep.value += 2
  }
  else if (externalMode.value && currentStep.value === 3 && isSponsoredPlan.value) {
    currentStep.value = 5
  }
  else if (currentStep.value === 4 && isSponsoredPlan.value && !externalMode.value) {
    currentStep.value = 6
  }
  else { ++currentStep.value }

  sendSegmentPage()
}

/**
 * Global func for updating signup tokens for current user
 *
 * @function
 * @param {object} data
 */
function updateCustomer(data) {
  if (data.pid)
    trackingParams.value.PID = data.pid
  else if (data.PID)
    trackingParams.value.PID = data.PID

  // Update customerData ref with incoming form data
  customerData.value = {
    ...customerData.value,
    ...data,
    ...(currentStep.value && { stepName: computedSteps.value[currentStep.value].name }),
  }

  // plan change to sponsored
  if (data?.sponsorCodeString)
    dispatchStepEvent()

  if (data.customerToken)
    sendSegmentIdentify()
}

/**
 * Format request body for POST calls
 * external mode means endpoints go to WAPI
 * WAPI request format application/json
 *
 * @function
 * @param {*} data
 *
 */
function getPostBody(data) {
  return externalMode.value
    ? JSON.stringify({
        ...data,
        ...(!!customerData.value?.trackingToken && {
          trackingToken: customerData.value.trackingToken,
        }),
      })
    : new URLSearchParams({
      ...data,
      ...(!!customerData.value?.trackingToken && {
        trackingToken: customerData.value.trackingToken,
      }),
      ...(!!customerData.value.customerToken && {
        customerToken: customerData.value.customerToken,
      }),
      clientKey: clientKey.value,
    })
}

/**
 * Formatting for tracking tokens
 * WAPI expects all lowercase
 * vs
 * SAPI all uppercase
 *
 * @function
 * @param {*} fields
 *
 */
function getTrackingTokens(fields = trackingParams.value) {
  return Object.fromEntries(
    Object.entries(fields).map(([k, v]) => [
      externalMode.value ? k.toLowerCase() : k.toUpperCase(),
      v,
    ]),
  )
}

/**
 * Clear form errors
 *
 * @function
 */
function clearFormError() {
  formError.value = ''
}

/**
 * Clear local storage
 *
 * @function
 */
function clearStorage() {
  localStorage.removeItem('cd_cstep')
  localStorage.removeItem('cd_ctoken')
  localStorage.removeItem('cd_ttoken')
}

/**
 * Displays form errors from emit
 *
 * @function
 * @param {Array} errors Optional - empty triggers default site error, otherwise need the error code to display message
 */
function setFormError(errors) {
  let code = ''
  if (errors && Array.isArray(errors) && errors.length > 0) {
    code = errors[0].code
    if (code === 'CHECK_SSN')
      currentStep.value = 2

    if (
      code === 'EXCEEDED_MAX_TRIES'
      || code === '400'
      || code === 'ID_VERIFICATION_EXCEEDED_MAX_TRIES'
      || code === 'BLACKBOX_FAILED_LOAD'
    ) {
      currentStep.value = null
      clearStorage()
    }

    formError.value = t(`errors["${code}"]`)
  }
  else {
    formError.value = t('errors.default')
  }

  // Emit error step on any breaking error - specify if outside US error
  let stepEventError = 'SignupError'

  if (code === 'LOCATION_NOT_ALLOWED')
    stepEventError = 'SignupErrorOutsideUS'

  if (code === 'BLACKBOX_FAILED_LOAD')
    stepEventError = 'SignupErrorBlackbox'

  if (currentStep.value === null)
    document.dispatchEvent(nextStepEvent(stepEventError))

  captureMessage(`Signup Form Error ${code ? `with code ${code}` : ''}`, currentStep.value === null ? 'warning' : 'info')
}

/**
 * Error out widget
 */
function errorSignup(errors) {
  loading.value = false
  setFormError(errors)
  currentStep.value = null
  sessionStorage.removeItem('wapi_token')
}

/**
 * Makes signup api call
 *
 * @function
 * @param {object} data
 * @param {string} data.url endpoint for the api call
 * @param {string} data.fields data to be passed with call
 * @param {string} data.method call type
 * @param {boolean} data.relativeUrl if true, do not prepend apiEndpoint
 */
async function processApiCall(data) {
  if (processing.value)
    return

  if (data.blocking)
    processing.value = true

  clearFormError()

  const isPost
    = data?.method === 'POST'
      || data?.method === 'DELETE'
      || data?.method === 'PUT'

  /**
   * Create URL object to build endpoint then append clientKey and trackingToken
   * Form fields are included in the fetch url for GET calls
   */
  const url = data.relativeUrl
    ? { href: data.url, searchParams: new URLSearchParams() }
    : new URL(`${apiEndpoint(data.url)}`)

  if (!isPost) {
    let fields = {
      ...data.fields, // Include form fields with GET calls
    }

    if (!externalMode.value) {
      fields = {
        ...fields,
        clientKey: clientKey.value,
        ...(!!customerData.value?.trackingToken && {
          trackingToken: customerData.value.trackingToken,
        }),
        ...(!!customerData.value.customerToken && {
          customerToken: customerData.value.customerToken,
        }),
      }
    }

    Object.entries(fields).forEach(([k, v]) => url.searchParams.append(k, v))
  }

  try {
    const token = sessionStorage.getItem('wapi_token')

    const response = await fetch(url.href, {
      method: data?.method ? data.method : 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': apiContentType.value,
        'Accept': 'application/json',
        ...(token
          && externalMode.value && { Authorization: `Bearer ${token}` }),
      },
      ...(isPost && { body: getPostBody(data.fields) }), // Move form fields to body for POST
    })

    if (response.ok) {
      try {
        const resp = await response.json()
        // Catch when the server returns a nonstandard error
        if (resp?.error)
          return { errors: [] }

        if (data?.signupMilestone)
          sendSegmentTrack(signupMilestoneName, data.signupMilestone)

        return resp
      }
      catch {
        // Response is good, but no json body...
        return {}
      }
    }
    else {
      try {
        const resp = await response.json()

        if (response.status === 401 && resp?.message === 'Unauthorized') {
          currentStep.value = null
          clearStorage()
          formError.value = t('errors.EXPIRED_SESSION')

          return {
            errors: [],
          }
        }

        return resp
      }
      catch (err) {
        captureException(err)
        return {
          errors: [],
        }
      }
    }
  }
  catch (err) {
    captureException(err)
    return {
      errors: [],
    }
  }
  finally {
    if (data.blocking)
      processing.value = false
  }
}

/**
 *
 * Authenticate session
 * when there is an otc made available to the widget
 * exchange otc for jwt for authenticated calls
 *
 * @function
 */
async function authenticateSession() {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_WAPI_SERVER}/v1/login?code=${registrantOtc.value}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    )

    if (response.ok) {
      const resp = await response.json()

      if (resp?.errors) {
        errorSignup(resp.errors)
        return false
      }
      else {
        sessionStorage.setItem('wapi_token', resp.access_token)
        return resp
      }
    }
    else {
      captureException(response)
      errorSignup()
      return false
    }
  }
  catch (err) {
    captureException(err)
    errorSignup()
    return false
  }
}

/**
 * Continues signup funnel
 *
 * @function
 */
async function continueSignup() {
  try {
    const authenticated = await authenticateSession()

    if (!authenticated)
      return

    let anonymousId = false

    if (segmentLoaded.value)
      anonymousId = await getAnonymousId()

    const tokens = getTrackingTokens()
    const appInit = {
      url: '/customer/continue',
      method: 'POST',
      fields: {
        ...tokens,
        productCode: productCode.value,
        ...(anonymousId && { anonymousId }),
      },
    }

    const resp = await processApiCall(appInit)

    if (resp?.errors) {
      errorSignup(resp.errors)
    }
    else {
      // Save returned continue DTO to customerData ref
      // and determine next step
      updateCustomer(resp)

      const stepIndex = computedSteps.value.findIndex(
        x => x.name === stepNameRef[resp.nextStep],
      )

      if (stepIndex > -1)
        currentStep.value = stepIndex
      else errorSignup()

      sendSegmentPage()
      loading.value = false
    }
  }
  catch (err) {
    captureException(err)
    errorSignup()
  }
}

/**
 * Starts signup funnel
 *
 * @function
 */
async function startSignup() {
  registrantOtc.value = document.getElementById('cd_registrant_otc')?.value

  if (registrantOtc.value) {
    continueSignup()
    return
  }

  let anonymousId = false

  if (segmentLoaded.value)
    anonymousId = await getAnonymousId()

  try {
    const tokens = getTrackingTokens()

    const appInit = {
      url: externalMode.value
        ? supportlinkSignup.value
          ? '/api/signup' // Relative url starts signup in sl so that it can be tagged with sl agent
          : '' // External mode starts signup in WAPI
        : '/start', // SAPI start
      ...(externalMode.value && {
        method: 'POST',
      }),
      ...(supportlinkSignup.value && externalMode.value && {
        relativeUrl: true,
      }),
      fields: {
        ...tokens,
        ...(externalMode.value && { productCode: productCode.value }),
        ...(anonymousId && { anonymousId }),
      },
    }

    const resp = await processApiCall(appInit)

    if (resp?.errors) {
      errorSignup(resp.errors)
    }
    else {
      // Load first step after track is started, this ensures we start with the correct PID
      currentStep.value = 0

      // Save returned trackingToken to customerData ref
      updateCustomer(resp)
      sendSegmentTrack(signupMilestoneName, signupMilestones.SIGNUP_STARTED)
      sendSegmentPage()
      loading.value = false
    }
  }
  catch (err) {
    captureException(err)
    errorSignup()
  }
}

/**
 * Validate hidden sponsorcode input
 * switch plan to sponsored if validated
 * NOT SUPPORTED in external mode
 *
 * @function
 */

async function lookupSponsorcode() {
  const resp = await processApiCall({
    url: '/validate/sponsor-code',
    fields: {
      sponsorCode: sponsorcodeInput,
    },
    blocking: true,
  })

  if (!resp?.errors) {
    updateCustomer({
      sponsorCodeString: sponsorcodeInput,
      ...(!!resp.PID && { PID: resp.PID }),
      ...(!!resp.pid && { PID: resp.pid }),
      planType: 'SPONSORED',
      isFinancialObligationMet: true,
    })
  }
  else {
    sponsorcodeLookupFailed.value = true
  }
}

/**
 * Lookup campaign details
 *
 * @function
 */
async function campaignLookup() {
  const appCampaign = {
    url: '/campaign',
    fields: {
      ...(externalMode.value && { productCode: productCode.value }),
      ...(trackingParams.value.PID && {
        [externalMode.value ? 'pid' : 'PID']: trackingParams.value.PID,
      }),
    },
  }
  try {
    const resp = await processApiCall(appCampaign)

    if (!resp?.errors) {
      trackingParams.value.PID = resp?.PID || resp?.pid
      localStorage.setItem('PID', trackingParams.value.PID)

      // Set offer and cobrand data for optional widgets
      offerData.value.memberPlans = resp.memberPlans

      const planTypes
        = resp.memberPlans?.planTypes || resp.memberPlans?.signupPlanTypes
      const plans = resp.memberPlans?.plans || resp.memberPlans?.signupPlans

      offerData.value.premiumPlanAvailable
        = planTypes.findIndex(x => x === 'PREMIUM') > -1
          && planTypes.findIndex(x => x === 'BASIC') > -1

      /**
       *
       * Setting default plan
       */
      const availablePlanTypes = planTypes.filter(x => x !== 'SPONSORED')

      if (availablePlanTypes.length < 2) {
        customerData.value.planType = availablePlanTypes[0]
      }
      else {
        if (availablePlanTypes.findIndex(x => x === 'BUNDLE') > -1) {
          customerData.value.planType = availablePlanTypes.find(
            x => x === 'BUNDLE',
          )
        }
        else {
          customerData.value.planType = plans.find(
            x => x?.isDefaultPlan || x?.isDefault,
          ).planType
        }
      }

      if (resp?.cobrand)
        cobrandData.value = resp.cobrand

      // Start new track after successful campaign call
      // no current stored progress
      if (currentStep.value < 1) {
        clearStorage()
        await startSignup()
      }
      else {
        loading.value = false
        updateCustomer({
          ...(!!trackingToken && { trackingToken }),
          ...(!!customerToken && { customerToken }),
        })
      }
      // Autoload hidden sponsor code
      if (hasSponsoredPlan.value && sponsorcodeInput)
        lookupSponsorcode()
    }
    else {
      const pidInvalidError
        = Array.isArray(resp.errors)
          ? resp.errors.findIndex(
            x =>
              x.code === 'PID_INVALID'
              || x.code === 'ID_CONFLICT'
              || (x.code === 'FIELD_INVALID' && x.field === 'pid'),
          ) > -1
          : false

      if (pidInvalidError) {
        trackingParams.value.PID = ''
        campaignLookup()
      }
      else {
        const errors
          = resp.errors.findIndex(x => x.code === 'LOCATION_NOT_ALLOWED') > -1
            ? setFormError(resp.errors)
            : null
        errorSignup(errors)
      }
    }
  }
  catch (err) {
    captureException(err)
    errorSignup()
  }
}

/**
 * Restart signup - clears all storage for a complete restart
 *
 * @function
 */
async function restartSignup() {
  clearStorage()
  clearFormError()
  currentStep.value = null

  // Delete everything except PID
  Object.keys(customerData.value).forEach(
    key => ['PID'].includes(key) || delete customerData.value[key],
  )
  offerData.value = {}
  cobrandData.value = {}

  // wait for next tick to clear all children form fields and vuelidate errors
  await nextTick()

  await campaignLookup()
}

/**
 * Define getter/setter for the hidden PID input
 * Allows us to update the PID and campaign when this hidden input is changed
 * Should only be used with PID select in Supportlink integration
 * We also look for an updated clientkey in the case of a product switch
 * Saves clientkey to hidden input if on page - this supports the pid switcher in supportlink
 *
 * @function
 */
function startPIDWatcher() {
  const input = document.getElementsByName('PID')[0]
  let value = input.value

  if (!Object.hasOwn(input, 'value')) {
    Object.defineProperty(input, 'value', {
      async set(newValue) {
        const changed = newValue !== trackingParams.value.PID

        /**
         *  The order we set these values in is very important,
         *  need to update the clientkey before changing the value of the hidden pid input
         */
        // update clientkey if necessary
        const ck = encodeURI(
          document.getElementById('cd-signup-widget').dataset?.clientkey,
        )
        if (ck && ck !== clientKey.value)
          changeClientkey(ck)
        // update product name if necessary
        const pc = encodeURI(
          document.getElementById('cd-signup-widget').dataset.productname,
        )
        if (pc !== productName.value)
          changeProductName(pc)

        value = newValue

        if (changed) {
          loading.value = true
          trackingParams.value.PID = value
          restartSignup()
        }
      },
      get() {
        return value
      },
    })
  }
}

function setTracking() {
  const tracking = externalMode.value ? new URLSearchParams() : urlParams

  useTracking(tracking, externalMode.value).getTracking()
}

/**
 * App mounted
 * Retrieve url params and match to expected tokens
 * Call signup/start and get trackingToken
 *
 */
onMounted(async () => {
  // Load segment script if enabled in env
  installSegment()

  // Set tracking variables from url or localStorage
  setTracking()

  prepopFields()

  // Look for hidden sponsorcode
  if (document.getElementsByName('sponsorcode').length > 0) {
    sponsorcodeInput = encodeURI(
      document.getElementsByName('sponsorcode')[0].value,
    )
  }
  else if (urlParams.get('sponsorCodeString')) {
    sponsorcodeInput = encodeURI(urlParams.get('sponsorCodeString'))
  }

  // Start input change listener to facilitate PID switcher
  if (document.getElementsByName('PID').length > 0)
    startPIDWatcher()

  await campaignLookup()
})
</script>

<template>
  <div class="cd-widget__container">
    <div class="cd-signup">
      <div
        v-if="restartAllowed"
        class="cd-signup__restart"
        @click="restartSignup()"
      >
        <RestartIcon />
      </div>
      <template v-if="!loading">
        <LanguageSelect v-if="switcherAllowed" widget="signup" />
        <FormError :message="formError" />
        <transition name="component-fade" mode="out-in">
          <component
            :is="computedSteps[currentStep]"
            :signup-api="processApiCall"
            :t="t"
            @form-error="setFormError"
            @clear-form-error="clearFormError"
            @update-customer="updateCustomer"
            @next-step="nextStep()"
            @previous-step="previousStep()"
            @dispatch-step-event="dispatchStepEvent"
          />
        </transition>
      </template>
      <div v-if="processing || loading" class="cd-loading">
        <svg class="cd-loading__spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
