<script setup>
import useVuelidate from '@vuelidate/core'
import { alphaNum, helpers } from '@vuelidate/validators'
import { creditCard } from '@/validation/validators'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'closeModal'])

const processing = inject('processing')
const i18n = inject('i18n')
const sponsorcodeLookupFailed = inject('sponsorcodeLookupFailed')

/**
 * Sponsor modal logic
 */
const sponsorCodeInvalid = ref(true)
const form = ref({
  sponsorCodeString: '',
})
const formResponse = ref('')

const rules = {
  sponsorCodeString: {
    alphaNum: helpers.withMessage(() => i18n.global.t('errors.invalidChars'), alphaNum),
    notCreditCard: helpers.withMessage(() => i18n.global.t('errors.SPONSOR_CODE_INVALID'), val => !form.value.sponsorCodeString || !creditCard(val)),
  },
}

const v$ = useVuelidate(rules, form, {})

onMounted(() => {
  if (sponsorcodeLookupFailed.value)
    formResponse.value = i18n.global.t('errors.SPONSOR_CODE_INVALID')
})

async function validateSponsorCode() {
  const resp = await props.signupApi({
    url: '/validate/sponsor-code',
    method: 'POST',
    fields: {
      sponsorCode: form.value.sponsorCodeString,
    },
    blocking: true,
  })

  if (resp?.errors) {
    const errorCode = resp.errors.length > 0 && resp?.errors[0]?.code ? resp.errors[0].code : 'SPONSOR_CODE_INVALID'
    sponsorCodeInvalid.value = true
    formResponse.value = i18n.global.t(`errors["${errorCode}"]`)
  }
  else {
    sponsorCodeInvalid.value = false
    formResponse.value = i18n.global.t('form.sponsorcodeSuccess')
    emits('updateCustomer', {
      sponsorCodeString: form.value.sponsorCodeString,
      ...(!!resp.PID && { PID: resp.PID }),
      planType: 'SPONSORED',
      isFinancialObligationMet: true,
    })
    setTimeout(() => emits('closeModal'), 500)
  }
}
</script>

<template>
  <div class="cd-sponsor-code-modal">
    <form role="form" novalidate="novalidate" autocomplete="off" class="cd-form">
      <div v-if="formResponse" :class="`cd-form-${sponsorCodeInvalid ? 'error' : 'success'}`">
        <p>
          {{ formResponse }}
        </p>
      </div>
      <InputText
        v-if="sponsorCodeInvalid"
        v-model="form.sponsorCodeString"
        type="sponsorcode"
        name="sponsorcode"
        :tabindex="6"
        :required="true"
        :label="i18n.global.t('form.sponsorcode')"
        :autofocus="true"
        :v="v$.sponsorCodeString"
        @input="v$.sponsorCodeString.$touch()"
      />
      <div v-if="sponsorCodeInvalid" class="cd-form__buttons-row">
        <button
          :disabled="form.sponsorCodeString.length < 1 || v$.invalid || processing"
          class="cd-btn"
          @click.prevent="validateSponsorCode"
        >
          {{ i18n.global.t('form.continue') }}
        </button>
        <p class="cd-form__buttons-row--link" @click="emits('closeModal')">
          {{ i18n.global.t('form.cancel') }}
        </p>
      </div>
    </form>
  </div>
</template>
