<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, requiredIf, sameAs } from '@vuelidate/validators'
import { creditCard, expirationDate, invalidChars, zip } from '@/validation/validators'
import { states } from '@/composables/states-list'
import { customerData, isSponsoredPlan, offerData, signupMilestoneName, signupMilestones } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'dispatchStepEvent',
])

/**
 * Injections
 *
 */
const memberSiteUrl = inject('memberSiteUrl')
const processing = inject('processing')
const productName = inject('productname')
const sendSegmentTrack = inject('sendSegmentTrack')

onMounted(() => emits('dispatchStepEvent'))

const ccNumberMask = ref('#### #### #### ####')

const sameBillingAddress = ref(true)
const showCityState = ref(false)
const zipErrorMessage = ref('')
const premiumPlan = ref(offerData.value.premiumPlanAvailable)
const numberInvalid = ref(false)
const form = ref({
  ccName: customerData.value?.name ? `${customerData.value.name?.firstName} ${customerData.value.name?.lastName}` : '',
  ccNumber: '',
  ccExp: '',
  ccCvv: '',
  street: customerData.value?.address?.street || '',
  city: customerData.value?.address?.city || '',
  state: customerData.value?.address?.state || '',
  zip: customerData.value?.address?.zip || '',
  isConfirmedTerms: customerData.value?.isConfirmedTerms
    ? customerData.value.isConfirmedTerms
    : false,
})

const premiumPlanAvailable = computed(() => offerData.value.premiumPlanAvailable)
const signupPlan = computed(() => premiumPlan.value ? 'PREMIUM' : 'BASIC')

/**
 * computed card type returns card type string
 * card type watcher updates ccNumber mask
 */
const cardType = computed(() => {
  const num = form.value.ccNumber.replace(/\s/g, '')

  if (/^5[1-5]/.test(num))
    return 'Mastercard'
  if (num.startsWith('4'))
    return 'Visa'
  if (/^3(?:4|7)/.test(num))
    return 'AMEX'
  if (/^6(?:011|5|4[4-9]|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d\d|9(?:[01]\d|2[0-5])))/.test(num))
    return 'Discover'
  return null
})

/**
 * Form validation
 * form <Object> ref for form inputs
 * mask <Object> ref for dynamic ccNumber masking
 * rules <Object> vuelidate rules object
 */
const rules = computed(() => ({
  ccName: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidChars'), invalidChars),
  },
  ccNumber: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    creditCard: helpers.withMessage(() => props.t('errors.creditCard'), creditCard),
    invalid: helpers.withMessage(() => props.t('errors.INVALID_CREDIT_CARD_NUMBER'), () => !numberInvalid.value),
  },
  ccExp: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    ...(!isSponsoredPlan.value && {
      expirationDate: helpers.withMessage(
        () => props.t('errors.expirationDate'),
        expirationDate,
      ),
    }),
  },
  ccCvv: {
    required: helpers.withMessage(
      () => props.t('errors.required'),
      requiredIf(!isSponsoredPlan.value),
    ),
    minLength: helpers.withMessage(() => props.t('errors.INVALID_CVV'), minLength(3)),
    maxLength: helpers.withMessage(() => props.t('errors.INVALID_CVV'), maxLength(4)),
  },
  street: {
    required: requiredIf(
      !isSponsoredPlan.value && (!sameBillingAddress.value || !customerData.value?.address?.street),
    ),
  },
  city: {
    required: requiredIf(showCityState.value),
  },
  state: {
    required: requiredIf(showCityState.value),
  },
  zip: {
    required: requiredIf(
      !isSponsoredPlan.value && (!sameBillingAddress.value || !customerData.value?.address?.zip),
    ),
    ...(!isSponsoredPlan.value
      && (!sameBillingAddress.value || !customerData.value?.address?.zip) && {
      zip: helpers.withMessage(() => props.t('errors.zip'), zip),
    }),
  },
  isConfirmedTerms: {
    required: sameAs(true),
  },
  sameBillingAddress: {},
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
}

function handleNumberChanged() {
  numberInvalid.value = false
}

async function changeMemberPlan() {
  const updateMemberPlan = await props.signupApi({
    url: '/customer/member-plan',
    method: 'PUT',
    fields: {
      planType: signupPlan.value,
    },
    blocking: true,
  })
  customerData.value.planType = signupPlan.value
}

async function submitOrder() {
  emits('clearFormError')
  zipErrorMessage.value = ''

  if (isSponsoredPlan.value) {
    emits('nextStep')
    return
  }

  const cardValidation = await props.signupApi({
    url: '/customer/validate/credit-card-number',
    method: 'POST',
    fields: {
      number: form.value.ccNumber.replace(/ /g, ''),
    },
    blocking: true,
  })

  if (!cardValidation?.creditCardToken || cardValidation?.errors) {
    numberInvalid.value = true
    if (cardValidation.errors.findIndex(x => x.code === 'FIELD_INVALID' || x.code === 'CREDIT_CARD_INVALID') < 0)
      emits('formError', cardValidation.errors)
  }
  else {
    sendSegmentTrack(signupMilestoneName, signupMilestones.CREDIT_CARD_STARTED)

    const resp = await props.signupApi({
      url: '/customer/credit-card',
      method: 'POST',
      fields: {
        creditCard: {
          name: form.value.ccName,
          expirationMonth: Number.parseInt(form.value.ccExp.slice(0, 2)),
          expirationYear: Number.parseInt(`20${form.value.ccExp.slice(-2)}`),
          cvv: form.value.ccCvv,
          token: cardValidation.creditCardToken,
          isAddressSameAsHome: sameBillingAddress.value,
          ...(!sameBillingAddress.value && {
            address: {
              street: form.value.street,
              zip: form.value.zip,
              ...(form.value.city && {
                city: form.value.city,
              }),
              ...(form.value.state && {
                state: form.value.state,
              }),
            },
          }),
        },
      },
      blocking: true,
    })

    sendSegmentTrack(signupMilestoneName, signupMilestones.CREDIT_CARD_ATTEMPT)

    if (!resp?.isFinancialObligationMet || resp?.errors) {
      const errorArr = ['ZIP_LOOKUP_FAILED', 'FIELD_INVALID_CHARACTERS', 'FIELD_REQUIRED']

      const errorIndex = resp.errors.findIndex(x => errorArr.includes(x.code))

      // Some errors we can help fix
      if (errorIndex !== -1) {
        const errorCode = resp.errors[errorIndex].code
        const zipFailed = errorCode === 'ZIP_LOOKUP_FAILED' || resp.errors[errorIndex].field.includes('city') || resp.errors[errorIndex].field.includes('state')

        if (zipFailed) {
          showCityState.value = true
          zipErrorMessage.value = props.t('errors.missingZip')
        }
        else {
          emits('formError', resp.errors[errorIndex])
        }
      }
      else {
        emits('formError', resp.errors)
      }
    }
    else {
      emits('updateCustomer', {
        ...resp,
      })
      emits('nextStep')
    }
  }
}

watch(
  () => cardType,
  (type) => {
    ccNumberMask.value = type.value === 'AMEX' ? '#### ###### #####' : '#### #### #### ####'
  },
  { immediate: true, deep: true },
)

watch(
  () => premiumPlan,
  async (newVal, oldVal) => {
    if (!oldVal)
      return

    await changeMemberPlan()
    emits('dispatchStepEvent')
  },
  { immediate: true },
)
</script>

<script>
export default {
  name: 'BillingStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.billing') }}
      </p>
      <PWSponsorCodeModal
        class="cd-mb-5"
        :signup-api="signupApi"
        @close-modal="openSponsorModal = false"
        @update-customer="emits('updateCustomer', $event)"
      />
      <template v-if="!isSponsoredPlan">
        <div class="cd-mb-5">
          <small class="cd-block cd-mb-2">{{ t('form.weAccept') }}</small>
          <div class="cd-pw-cc-types">
            <VisaCCIcon />
            <MastercardCCIcon />
            <AmexCCIcon />
            <DiscoverCCIcon />
          </div>
        </div>
        <InputText
          v-model.trim="form.ccName"
          name="ccName"
          :tabindex="1"
          :required="true"
          :label="t('form.ccName')"
          :v="v$.ccName"
          :t="t"
          @blur="inputBlur('ccName')"
        />
        <InputText
          v-model.trim="form.ccNumber"
          name="ccNumber"
          :tabindex="2"
          :required="true"
          :autofocus="true"
          :label="t('form.ccNumber')"
          :mask="ccNumberMask"
          autocomplete="off"
          :v="v$.ccNumber"
          :t="t"
          @blur="inputBlur('ccNumber')"
          @changed="handleNumberChanged"
        />
        <div class="cd-form__input-group">
          <InputText
            v-model.trim="form.ccExp"
            name="ccExp"
            class="cd-flex-1"
            :tabindex="3"
            :required="true"
            :label="t('form.expDate')"
            mask="##/##"
            placeholder="MM/YY"
            autocomplete="off"
            :v="v$.ccExp"
            :t="t"
            @blur="inputBlur('ccExp')"
          />
          <InputText
            v-model.trim="form.ccCvv"
            name="ccCvv"
            class="cd-flex-1"
            :tabindex="4"
            :required="true"
            :label="t('form.cvv')"
            :mask="['###', '####']"
            autocomplete="off"
            :v="v$.ccCvv"
            :t="t"
            @blur="inputBlur('ccCvv')"
          >
            <InputHelpIcon />
          </InputText>
        </div>
        <InputCheckbox v-if="customerData?.address?.zip && customerData?.address?.street" v-model="sameBillingAddress" name="billingAddress" :v="v$.sameBillingAddress">
          {{ t('form.billingSameAs') }}
        </InputCheckbox>
        <div v-if="!sameBillingAddress || !customerData?.address?.zip || !customerData?.address?.street">
          <InputText
            v-model.trim="form.street"
            name="street"
            :tabindex="4"
            :required="true"
            :label="t('form.billingAddress')"
            :v="v$.street"
            :t="t"
            @blur="inputBlur('street')"
          />
          <div v-if="showCityState" class="highlight">
            <p class="formError" v-html="zipErrorMessage" />
            <InputText
              v-model.trim="form.city"
              name="city"
              :tabindex="5"
              :required="true"
              :label="t('form.city')"
              :v="v$.city"
              :t="t"
              @blur="inputBlur('city')"
            />
            <InputSelect
              v-model.trim="form.state"
              name="state"
              :tabindex="6"
              :required="true"
              :label="t('form.state')"
              :optionslist="states"
              :v="v$.state"
              :t="t"
              @blur="inputBlur('state')"
            />
          </div>
          <div class="cd-form__input-group">
            <InputText
              v-model.trim="form.zip"
              name="zip"
              class="cd-flex-1"
              :tabindex="7"
              :required="true"
              :label="t('form.billingZip')"
              mask="#####"
              :v="v$.zip"
              :t="t"
              @blur="inputBlur('zip')"
            />
            <div class="cd-flex-1" />
          </div>
        </div>
        <InputCheckbox v-if="premiumPlanAvailable" v-model="premiumPlan" name="premiumPlan" :v="v$.premiumPlan">
          {{ t('form.premiumPlan') }}
        </InputCheckbox>
      </template>
      <InputCheckbox v-if="!customerData?.isConfirmedTerms" v-model="form.isConfirmedTerms" name="terms" :v="v$.isConfirmedTerms">
        <i18n-t
          keypath="form.confirmTerms"
          tag="span"
          scope="global"
        >
          <template #product>
            {{ productName }}
          </template>
          <template #serviceAgreement>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/service-agreement.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.serviceAgreement') }}</a>
          </template>
          <template #terms>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/site-use.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.terms') }}</a>
          </template>
          <template #privacy>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/privacy-policy.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.privacy') }}</a>
          </template>
          <template #rights>
            <a
              :href="`${memberSiteUrl}/help/terms-and-privacy/consumer-rights.htm?PID=${customerData.pid}`"
              target="_blank"
            >{{ t('form.rights') }}</a>
          </template>
        </i18n-t>
      </InputCheckbox>
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        class="cd-btn"
        type="submit"
        tabindex="9"
        :disabled="v$.$invalid || processing"
        @click.prevent="submitOrder()"
      >
        {{ t('form.step4Submit') }}
      </button>
    </div>
  </form>
</template>
