<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required, requiredIf } from '@vuelidate/validators'
import { customerData, signupMilestoneName, signupMilestones } from '@/store'
import dVLoaderScript from '@/modules/dVLoader'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'previousStep',
  'dispatchStepEvent',
])

const processing = inject('processing')
const sendSegmentTrack = inject('sendSegmentTrack')

const formProcessing = ref(false)

const token = sessionStorage.getItem('wapi_token')

/**
 * DV refs
 * Digital Verification global variable declaration
 */

// Blackbox data that is to be passed to backend, watched by
const dVBlackbox = ref('')
const dvScriptLoaded = ref(false)
const dVCheckFunctionCheckCounter = ref(0)
// maximum times that we will check if Blackbox getter function is set
const dVCheckFunctionCheckMaxInterval = 20

/**
 * getBlackboxCallBack is the DV config callback function
 */
function getBlackboxCallBack(bb, complete) {
  if (!dvScriptLoaded.value)
    dvScriptLoaded.value = true

  if (!dVBlackbox.value) {
    // function will attempt to get a completed blackbox value a maximum of 20 times before throwing an error
    if (dVCheckFunctionCheckCounter.value < dVCheckFunctionCheckMaxInterval) {
      if (complete)
        dVBlackbox.value = bb
      else
        dVCheckFunctionCheckCounter.value++
    }
    else {
      emits('formError', [{ code: 'BLACKBOX_FAILED_LOAD' }])
    }
  }
}

/**
 * Questions ref tracks the current questions returned to answer
 * Each submission requires the reference number supplied with questions
 *
 */
const questions = ref([])

/**
 * OTP refs
 */
const idVerificationCriteria = ref()
const failedPinValidation = ref(false)
const showResetLink = ref(false)
const isIDMAPinVerify = ref(false)

const isIDMAChoice = computed(() => idVerificationCriteria.value?.length ? idVerificationCriteria.value[0]?.name === 'IDM_Choice' : false)

/*
 * Watch for pinverify step
 */
watch(
  () => isIDMAPinVerify,
  (a) => {
    if (a.value)
      setTimeout(() => showResetLink.value = true, 3000)
  },
  { immediate: true, deep: true },
)

/**
 * Once dVBlackbox is set load questions to populate form
 */
watch(dVBlackbox, () => {
  getVerificationQuestions()
})

/**
 * Form validation
 * form <Object> ref for form inputs
 * error <String> ref for step specific errors
 * rules <Object> vuelidate rules object
 */
const form = ref({
  answers: [],
  passcode: '',
})

const error = ref('')

const rules = {
  answers: {
    $each: helpers.forEach({
      answer: {
        required,
      },
    }),
  },
  passcode: {
    required: helpers.withMessage(
      '',
      requiredIf(() => isIDMAPinVerify.value),
    ),
    minLength: helpers.withMessage(() => props.t('errors.pinInvalid'), minLength(4)),
    maxLength: helpers.withMessage(() => props.t('errors.pinInvalid'), maxLength(8)),
    validChars: helpers.withMessage(() => props.t('errors.pinInvalid'), value => /^[A-Za-z0-9-_]*$/.test(value)),
    validate: helpers.withMessage(() => props.t('errors.sponsorCodeValid'), () => !failedPinValidation.value),
  },
}

const v$ = useVuelidate(rules, form, {})

/**
 * Clear questions and form
 */
function clearForm() {
  questions.value = []
  form.value.answers = []
  form.value.passcode = ''
  showResetLink.value = false
}

/**
 * Load questions into ref
 */
function loadQuestions(idmaQuestions) {
  clearForm()

  idVerificationCriteria.value = questions.value = idmaQuestions
  form.value.answers.push(...Array(idmaQuestions.length).fill({ answer: '' }))
}

function setAnswer(answer, index) {
  form.value.answers[index] = answer
}

async function submitIDVAnswers(fields) {
  return await fetch(`${import.meta.env.VITE_WAPI_SERVER}/v1/signup/customer/id-verification/answer`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      trackingToken: customerData.value.trackingToken,
      clientDigitalFootprint: dVBlackbox.value,
      answers: fields,
    }),
  })
}

async function submitIDVPasscode(passcode) {
  return await fetch(`${import.meta.env.VITE_WAPI_SERVER}/v1/signup/customer/id-verification/passcode`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      trackingToken: customerData.value.trackingToken,
      clientDigitalFootprint: dVBlackbox.value,
      passcode,
    }),
  })
}

/**
 * Form submission
 * Submit answers to ID verification
 * On error reload verification questions
 *
 */
async function verifyIdentity() {
  formProcessing.value = true

  emits('clearFormError')
  error.value = ''

  let response = null
  let resp = null

  // Request object needs user input if pin verify step of otp
  if (isIDMAPinVerify.value) {
    response = await submitIDVPasscode(form.value.passcode)
  }
  else {
    const answers = []

    // map reponse object from form ref
    form.value.answers.forEach((answer, index) => {
      answers.push({
        questionName: questions.value[index].name,
        choiceKey: form.value.answers[index],
      })
    })

    response = await submitIDVAnswers(answers)
  }

  try {
    resp = await response.json()
  }
  catch {
    clearForm()
    emits('formError')
    getVerificationQuestions()
    formProcessing.value = false
    return
  }

  formProcessing.value = false

  if (response.status === 200) {
    sendSegmentTrack(signupMilestoneName, signupMilestones.IDMA_COMPLETED)
    // Successful ID verification, store progress
    emits('nextStep')

    return
  }

  // more questions
  if (response.status === 201) {
    loadQuestions(resp.questions)

    return
  }

  // otp passcode type chosen
  if (response.status === 202) {
    isIDMAPinVerify.value = true

    return
  }

  if (resp?.errors.find(x => x.code === 'ID_VERIFICATION_MORE_QUESTIONS') && isIDMAPinVerify.value) {
    failedPinValidation.value = true

    return
  }

  clearForm()

  if (resp?.errors.find(x => x.code === 'ID_VERIFICATION_FAILED')) {
    emits('previousStep')
    emits('formError', resp.errors)

    return
  }

  if (resp?.errors.find(x => x.code === 'EXCEEDED_MAX_TRIES' || x.code === 'ID_VERIFICATION_EXCEEDED_MAX_TRIES')) {
    emits('formError', resp.errors)
  }
  else {
    getVerificationQuestions()
    emits('formError', resp?.errors)
  }
}

/**
 * Get ID verification questions and create question and matching answer arrays for ref
 *
 */
async function getVerificationQuestions() {
  const resp = await props.signupApi({
    url: '/customer/id-verification',
    method: 'POST',
    fields: {
      trackingToken: customerData.value.trackingToken,
      clientDigitalFootprint: dVBlackbox.value,
    },
    blocking: true,
    signupMilestone: signupMilestones.IDMA_QUESTIONS_PRESENTED,
  })

  if (resp?.errors) {
    if (
      Array.isArray(resp.errors)
      && resp.errors.findIndex(x => x.code === 'ID_ALREADY_VERIFIED') > -1
    ) {
      emits('nextStep')
    }
    else if (Array.isArray(resp.errors) && resp.errors.findIndex(x => x.code === 'FIELD_INVALID') > -1) {
      emits('previousStep')
      emits('formError')
    }
    else {
      emits('previousStep')
      emits('formError', resp.errors)
    }
  }
  else {
    if (resp.questions[0].name === 'IDM_PINVERIFY')
      isIDMAPinVerify.value = true

    if (!isIDMAPinVerify.value)
      loadQuestions(resp.questions)

    // Reset form validation
    v$.value.$reset()
  }

  formProcessing.value = false
}

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()

  if (input === 'passcode' && failedPinValidation.value)
    failedPinValidation.value = false
}

async function restartIDMA() {
  formProcessing.value = true
  try {
    const resp = await props.signupApi({
      url: '/customer/id-verification',
      method: 'DELETE',
      fields: {
        trackingToken: customerData.value.trackingToken,
      },
      blocking: true,
    })

    if (!resp?.errors) {
      clearForm()

      emits('previousStep')
      emits('formError', [{ code: 'ID_VERIFICATION_OTP_RESTART' }])
    }
    else {
      emits('formError', resp.errors)
    }
  }
  catch {
    clearForm()

    emits('previousStep')
    emits('formError', [{ code: 'ID_VERIFICATION_OTP_RESTART' }])
  }
  finally {
    formProcessing.value = false
  }
}

function dvScriptWatcher() {
  setTimeout(() => {
    // if the script fails to load, throw error
    if (!dvScriptLoaded.value)
      emits('formError', [{ code: 'BLACKBOX_FAILED_LOAD' }])
  }, 1000)
}

/**
 * On component mount
 * Emit step event
 * load DV loader_only script, uses callback function bb_callback to set black box as dVBlackbox ref
 */
onMounted(() => {
  emits('dispatchStepEvent')

  formProcessing.value = true

  window.io_global_object_name = 'IGLOO'
  window.IGLOO = window.IGLOO || {
    enable_flash: false,
    //  bb_callback sets dVBlackbox value once loader function gathers async data
    bb_callback: getBlackboxCallBack,
    loader: {
      subkey: 'i07bAlexpwOuUoOhI2Z81QL0xKnFrxmVSbWC7Ar-XH4',
      version: 'general5',
    },
  }

  dVLoaderScript()

  dvScriptWatcher()
})

onUnmounted(() => {
  window.IGLOO = null
})
</script>

<script>
export default {
  name: 'IdentityStep',
}
</script>

<template>
  <form v-if="!processing" role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        <template v-if="isIDMAChoice">
          {{ t('headings.identityOtpChoice') }} <small>{{ t('headings.identityOtpChoiceSmall') }}</small>
        </template>
        <template v-else-if="isIDMAPinVerify">
          {{ t('headings.identityOtpVerify') }} <small>{{ t('headings.identityOtpVerifySmall', customerData?.phone?.slice(-4)) }}</small>
        </template>
        <template v-else>
          {{ t('headings.identity') }} <small>{{ t('headings.identitySmall') }}</small>
        </template>
      </p>
      <div v-if="isIDMAPinVerify">
        <InputText
          v-model="form.passcode"
          name="passcode"
          :required="isIDMAPinVerify"
          :label="t('form.verificationCode')"
          :tabindex="1"
          :minlength="4"
          :maxlength="8"
          :v="v$.passcode"
          @blur="inputBlur('passcode')"
        />
      </div>
      <transition-group v-else name="fade">
        <div v-for="(question, index) in questions" :key="question.name" class="cd-radio">
          <label v-if="!isIDMAChoice" :for="`answers${index}`">{{ question.displayName }}</label>
          <div
            v-for="(choice, ind) in question.choices"
            :key="ind"
            class="cd-radio__input"
          >
            <label>
              <input
                type="radio"
                :value="choice.key"
                :name="`answers${index}`"
                :checked="false"
                @input="setAnswer(choice.key, index)"
              >
              <span>{{ choice.display }}</span>
            </label>
          </div>
        </div>
      </transition-group>
    </fieldset>
    <div v-if="isIDMAPinVerify || questions.length" class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="9"
        :disabled="v$.$invalid || formProcessing"
        @click.prevent="verifyIdentity"
      >
        {{ t('form.step3Submit') }}
      </button>
      <transition name="fade" mode="out-in">
        <p v-if="showResetLink" class="cd-text-primary cd-cursor-pointer" @click="restartIDMA">
          <small>{{ t('form.restartOtp') }}</small>
        </p>
      </transition>
    </div>
  </form>
</template>
