<script setup>
import useVuelidate from '@vuelidate/core'
import { email, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { emailNoDomain, invalidPWChars } from '@/validation/validators'
import { customerData, encodedTrackingParams, hasSponsoredPlan, isSponsoredPlan, presetEmail, signupMilestones, trackingParams } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'nextStep', 'dispatchStepEvent'])

const ratePassword = typeof zxcvbn === 'function'

const memberSiteUrl = inject('memberSiteUrl')
const processing = inject('processing')
const sponsorcodeLookupFailed = inject('sponsorcodeLookupFailed')
const productName = inject('productName')

watch(sponsorcodeLookupFailed, (newVal, oldVal) => {
  if (newVal)
    openSponsorModal.value = true
})

const emailDomain = ref('')

onMounted(() => {
  if (document.getElementsByName('company_email').length > 0)
    emailDomain.value = document.getElementsByName('company_email')[0].value
  emits('dispatchStepEvent')
})

/**
 * Form validation
 * Refs to track specific error cases and the needed UI changes
 * form <Object> ref for form inputs
 * rules <Object> vuelidate rules object
 */
const passwordScore = ref()
const scoreReason = ref('')
const emailReason = ref('')
const emailChanged = ref(true)
const emailUnique = ref(true)
const openSponsorModal = ref(false)

const computedEmail = computed(() => {
  if (emailDomain.value)
    return `${form.value.email}@${emailDomain.value}`
  else return form.value.email
})

const emailMask = computed(() => {
  if (emailDomain.value)
    return { mask: 'Z*', tokens: { Z: { pattern: /[a-zA-Z0-9-_.]/ } } }
  else return null
})

const form = ref({
  email: presetEmail.value ? presetEmail.value : '',
  password: '',
  isConfirmedTerms: false,
})

const rules = computed(() => ({
  email: {
    required: helpers.withMessage('', required),
    ...(!emailDomain.value && {
      email: helpers.withMessage(() => props.t('errors.email'), email),
    }),
    ...(emailDomain.value && {
      emailNoDomain: helpers.withMessage(() => props.t('errors.invalidChars'), emailNoDomain),
    }),
    unique: helpers.withMessage(
      () =>
        memberSiteUrl
          ? props.t('errors.EMAIL_USED', { loginLink: `<a href="${memberSiteUrl}/login/?${encodedTrackingParams.value}">${props.t('form.login')}</a>` })
          : props.t('errors.EMAIL_USED', { loginLink: 'login' }),
      () => emailUnique.value,
    ),
  },
  password: {
    required: helpers.withMessage('', required),
    score: helpers.withMessage(
      () => (scoreReason.value ? scoreReason.value : props.t('errors.passwordScore')),
      () => (ratePassword ? passwordScore.value > 2 : true),
    ),
    invalidChars: helpers.withMessage(() => props.t('errors.invalidPWChars'), invalidPWChars),
    minLength: helpers.withMessage(() => props.t('errors.minLength'), minLength(8)),
    maxLength: helpers.withMessage(() => props.t('errors.maxLength'), maxLength(100)),
  },
  isConfirmedTerms: {
    // required: helpers.withMessage(() => props.t('errors.confirmTerms'), sameAs(true)),
  },
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handle score changes for password difficulty
 */
function scoreUpdate(score) {
  scoreReason.value = ''
  passwordScore.value = score.score
  scoreReason.value = score.feedback.warning
}

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
  else return

  if (v$.value[input].$invalid)
    return

  if (input === 'email' && emailChanged.value) {
    emailChanged.value = false
    const resp = await props.signupApi({
      url: '/validate/email',
      method: 'POST',
      fields: { email: computedEmail.value },
      blocking: false,
    })
    if (resp?.errors) {
      if (resp.errors[0]?.code === 'EMAIL_USED')
        emailUnique.value = false
      else
        emits('formError', resp.errors)
    }
    else {
      emailUnique.value = true
    }
  }
}

function handleEmailChanged() {
  emailChanged.value = true
  emailUnique.value = true
  emailReason.value = ''
}

function updateSponsorCode(e) {
  form.value.sponsorCodeString = e.sponsorCodeString
  emits('updateCustomer', e)
}

/**
 * Form submission
 * Progress is not stored in localStorage bc the account is not officially created on the backend until after the personal step
 *
 */
async function createCustomer() {
  const resp = await props.signupApi({
    url: '/customer/create',
    method: 'POST',
    fields: {
      email: computedEmail.value,
      password: form.value.password,
      PID: trackingParams.value.PID,
      ...(customerData.value.isFinancialObligationMet && {
        isFinancialObligationMet: customerData.value.isFinancialObligationMet,
      }),
      ...(customerData.value.planType && {
        planType: customerData.value.planType,
      }),
      ...(customerData.value.sponsorCodeString && {
        sponsorCodeString: customerData.value.sponsorCodeString,
      }),
    },
    blocking: true,
    signupMilestone: signupMilestones.LEAD_GENERATED,
  })

  if (resp?.errors) {
    emits('formError', resp.errors)
  }
  else {
    emits('updateCustomer', {
      ...resp,
      email: computedEmail.value,
      isConfirmedTerms: form.value.isConfirmedTerms,
    })
    emits('nextStep')
  }
}
</script>

<script>
export default {
  name: 'AccountStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.account', { product: productName }) }}
      </p>
      <div :class="{ 'cd-form__email-domain-container': emailDomain.length > 0 }">
        <InputText
          v-model="form.email"
          type="email"
          name="email"
          :tabindex="4"
          :required="true"
          :label="t('form.email')"
          :autofocus="true"
          :mask="emailMask"
          :v="v$.email"
          @blur="inputBlur('email')"
          @changed="handleEmailChanged()"
        >
          <p v-if="emailDomain.length > 0" class="cd-form__email-domain">
            @{{ emailDomain }}
          </p>
        </InputText>
      </div>
      <InputPassword
        v-model="form.password"
        name="password"
        :required="true"
        :label="t('form.password')"
        :tabindex="5"
        :rate-password="ratePassword"
        :placeholder="!ratePassword ? t('form.passwordPlaceholder') : ''"
        :v="v$.password"
        @blur="inputBlur('password')"
        @score-update="scoreUpdate"
      />
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="6"
        :disabled="v$.$invalid || processing"
        @click.prevent="createCustomer()"
      >
        {{ t('form.step1Submit') }}
      </button>
      <p
        v-if="hasSponsoredPlan && !isSponsoredPlan"
        class="cd-form__buttons-row--link"
        @click="openSponsorModal = true"
      >
        {{ t('form.sponsorcodePrompt') }}
      </p>
      <div v-if="isSponsoredPlan" class="cd-form__buttons-row--sponsorcode">
        <span>{{ t('form.sponsorcode') }}:</span>
        <strong class="cd-sponsorcode">{{ customerData.sponsorCodeString }}</strong>
        <i class="cd-checkmark" />
      </div>
    </div>
    <transition name="fade">
      <SponsorCodeModal
        v-if="openSponsorModal"
        :signup-api="signupApi"
        @close-modal="openSponsorModal = false"
        @update-customer="updateSponsorCode"
      />
    </transition>
  </form>
</template>
