<script setup>
import { onBeforeUnmount } from 'vue'

const i18n = inject('i18n')

const steps = ['AccountStep', 'PersonalStep', 'IdentityStep', 'BillingStep', 'ConfirmationStep']

const currentStepName = ref('')

const currentStep = computed(() => steps.findIndex(x => x === currentStepName.value) + 1)

function stepHandler(step) {
  currentStepName.value = step.step
}

onMounted(() => {
  document.addEventListener('cd-signup-next-step', (e) => {
    stepHandler(e.detail)
  })
})

onBeforeUnmount(() => document.removeEventListener('cd-signup-next-step', null))
</script>

<template>
  <div class="cd-widget__container">
    <ul class="cd-progress-bar">
      <li :class="{ active: currentStep < 3, completed: currentStep > 2 }">
        <svg
          v-if="currentStep > 2"
          class="cd-step__number"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
            fill="currentColor"
          />
        </svg>
        <span v-else class="cd-step__number">1</span>
      </li>
      <div class="cd-progress-bar__separator" />
      <li :class="{ active: currentStep === 3, completed: currentStep > 3 }">
        <svg
          v-if="currentStep > 3"
          class="cd-step__number"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
            fill="currentColor"
          />
        </svg>
        <span v-else class="cd-step__number">2</span>
      </li>
      <div class="cd-progress-bar__separator" />
      <li :class="{ active: currentStep === 4, completed: currentStep > 4 }">
        <svg
          v-if="currentStep > 4"
          class="cd-step__number"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
            fill="currentColor"
          />
        </svg>
        <span v-else class="cd-step__number">3</span>
      </li>
    </ul>
    <ul class="cd-progress-bar hide-mobile">
      <li :class="{ active: currentStep < 3, completed: currentStep > 2 }">
        <span class="cd-step__label">{{ i18n.global.t('steps.1') }}</span>
      </li>
      <li :class="{ active: currentStep === 3, completed: currentStep > 3 }">
        <span class="cd-step__label">{{ i18n.global.t('steps.2') }}</span>
      </li>
      <li :class="{ active: currentStep === 4, completed: currentStep > 4 }">
        <span class="cd-step__label">{{ i18n.global.t('steps.3') }}</span>
      </li>
    </ul>
  </div>
</template>
