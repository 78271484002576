<script setup>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'nextStep', 'dispatchStepEvent'])

const processing = inject('processing')

const securityQuestions = ref([])

const formattedSecurityQuestions = computed(() => {
  return securityQuestions.value.map(question => ({
    value: question.securityQuestionId,
    label: question.question,
  }))
})

/**
 * Form validation
 * Refs to track specific error cases and the needed UI changes
 * form <Object> ref for form inputs
 * rules <Object> vuelidate rules object
 */
const form = ref({
  securityQuestionAnswer: {
    securityQuestionId: null,
    answer: '',
  },
})

const rules = computed(() => ({
  securityQuestionAnswer: {
    securityQuestionId: {
      required: helpers.withMessage('', required),
    },
    answer: {
      required: helpers.withMessage('', required),
    },
  },
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 */
async function inputBlur(input) {
  if (form.value[input])
    v$.value[input].$touch()
}

/**
 * Form submission
 * Progress is not stored in localStorage bc the account is not officially created on the backend until after the personal step
 *
 */
async function saveSecurityAnswer() {
  emits('updateCustomer', {
    ...form.value,
  })
  emits('nextStep')
}

async function getSecurityQuestions() {
  const resp = await props.signupApi({
    url: '/security-questions',
    method: 'GET',
    blocking: true,
  })

  if (resp?.errors)
    emits('formError', resp.errors[0])

  else
    securityQuestions.value = resp.securityQuestions
}

onMounted(async () => {
  await getSecurityQuestions()
  emits('dispatchStepEvent')
})
</script>

<script>
export default {
  name: 'SecurityQuestionsStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.passwordRecoverySetup') }}
      </p>
      <InputSelect
        v-model.trim="form.securityQuestionAnswer.securityQuestionId"
        name="securityQuestionId"
        :tabindex="6"
        :required="true"
        :label="t('form.securityQuestion')"
        :optionslist="formattedSecurityQuestions"
        :v="v$.securityQuestionAnswer.securityQuestionId"
        :t="t"
        @blur="inputBlur('securityQuestionAnswer.securityQuestionId')"
      />
      <InputText
        v-model.trim="form.securityQuestionAnswer.answer"
        name="answer"
        :tabindex="1"
        :required="true"
        :label="t('form.answer')"
        :v="v$.securityQuestionAnswer.answer"
        :t="t"
        @blur="inputBlur('securityQuestionAnswer.answer')"
      />
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="6"
        :disabled="v$.$invalid || processing"
        @click.prevent="saveSecurityAnswer()"
      >
        {{ t('form.saveAnswer') }}
      </button>
    </div>
  </form>
</template>
