<script setup>
import { analytics, segmentLoaded } from '@/modules/segment'
import { customerData, hasTrial, signupMilestones } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'dispatchStepEvent'])

const memberSiteUrl = inject('memberSiteUrl')
const productName = inject('productName')

const tempPassword = ref()

/**
 * Signup complete api call
 * Loads temp password for quick login, clears localStorage
 */
onMounted(async () => {
  const resp = await props.signupApi({
    url: '/complete',
    method: 'POST',
    blocking: true,
    signupMilestone: signupMilestones.SIGNUP_COMPLETED,
  })

  localStorage.removeItem('cd_ctoken')
  localStorage.removeItem('cd_ttoken')
  localStorage.removeItem('cd_cstep')

  if (resp?.errors) {
    emits('formError', resp.errors[0])
  }
  else {
    customerData.value.orderComplete = true
    tempPassword.value = resp.temporaryPassword
    emits('dispatchStepEvent')
  }
})

/**
 * Clear segment cookies before navigating away from this step
 */
onUnmounted(() => {
  if (segmentLoaded.value)
    analytics.reset()
})
</script>

<script>
export default {
  name: 'ConfirmationStep',
}
</script>

<template>
  <div v-if="customerData.orderComplete" class="cd-form">
    <fieldset>
      <p class="cd-form__legend cd-mb-5">
        {{ t('headings.welcomeToMembership', { product: productName }) }}
      </p>
      <div class="cd-flex cd-mb-5">
        <div class="cd-flex-1">
          <p>
            <strong>{{ t('form.username') }}</strong><br>{{ customerData.email }}
          </p>
          <p>
            <strong>{{ t('form.membertype') }}</strong><br>
            {{ customerData.planType }}
          </p>
          <form
            class="cd-form"
            role="form"
            method="post"
            :action="`${memberSiteUrl}/external-login`"
          >
            <input type="hidden" name="loginType" value="CUSTOMER">
            <input type="hidden" name="j_username" :value="customerData.email">
            <input type="hidden" name="j_password" :value="tempPassword">
            <button type="submit" class="cd-btn">
              {{ t('form.step5Submit') }}
            </button>
          </form>
        </div>
        <div class="cd-flex-1">
          <p>
            <strong>{{ customerData.name }}</strong>
          </p>
          <p class="cd-mb-5">
            {{ customerData.street }}<br>{{ customerData.city }}, {{ customerData.state }}
            {{ customerData.zip }}
          </p>
        </div>
      </div>
      <div class="cd-mt-4">
        <p>
          <template v-if="hasTrial">
            {{ t('form.hasTrial', { product: productName }) }}
          </template>
        </p>
      </div>
    </fieldset>
  </div>
</template>
