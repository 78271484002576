<script setup>
import { useVuelidate } from '@vuelidate/core'
import { alphaNum, helpers } from '@vuelidate/validators'
import { creditCard } from '@/validation/validators'
import { hasSponsoredPlan } from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits(['formError', 'clearFormError', 'updateCustomer', 'closeModal'])

const processing = inject('processing')
const i18n = inject('i18n')
const sponsorcodeLookupFailed = inject('sponsorcodeLookupFailed')

/**
 * Sponsor modal logic
 */
const openSponsorModal = ref(false)
const sponsorCodeError = ref(false)
const formResponse = ref('')

const form = ref({
  sponsorCodeString: '',
})

const rules = {
  sponsorCodeString: {
    alphaNum: helpers.withMessage(() => i18n.global.t('errors.invalidChars'), alphaNum),
    notCreditCard: helpers.withMessage(() => i18n.global.t('errors.SPONSOR_CODE_INVALID'), val => !form.value.sponsorCodeString || !creditCard(val)),
  },
}

const v$ = useVuelidate(rules, form, {})

onMounted(() => {
  if (sponsorcodeLookupFailed.value)
    formResponse.value = i18n.global.t('errors.SPONSOR_CODE_INVALID')
})

async function validateSponsorCode() {
  const resp = await props.signupApi({
    url: '/customer/sponsor-code',
    method: 'POST',
    fields: {
      sponsorCode: form.value.sponsorCodeString,
    },
    blocking: true,
  })

  if (resp?.errors || resp?.error) {
    const errorCode = resp?.errors.length > 0 && resp.errors[0]?.code ? resp.errors[0].code : 'SPONSOR_CODE_INVALID'
    sponsorCodeError.value = true
    formResponse.value = i18n.global.t(`errors["${errorCode}"]`)
  }
  else {
    sponsorCodeError.value = false
    formResponse.value = i18n.global.t('form.sponsorcodeSuccess')
    emits('updateCustomer', {
      sponsorCodeString: form.value.sponsorCodeString,
      ...resp,
    })
  }
}
</script>

<template>
  <div>
    <div
      v-if="hasSponsoredPlan"
      class="cd-form__sponsorcode-link"
      :class="{ 'cd-form__sponsorcode-link_open': openSponsorModal }"
      @click="openSponsorModal = !openSponsorModal"
    >
      <span>{{ i18n.global.t('form.sponsorcodePrompt') }}</span>
    </div>
    <transition name="accordion">
      <div v-if="openSponsorModal" class="cd-form__sponsorcode_dropdown">
        <form role="form" novalidate="novalidate" autocomplete="off" class="cd-form">
          <InputText
            v-model="form.sponsorCodeString"
            type="sponsorcode"
            name="sponsorcode"
            :class="{ 'cd-form-error': sponsorCodeError }"
            :tabindex="6"
            :required="true"
            :label="i18n.global.t('form.sponsorcode')"
            :autofocus="true"
            :v="v$.sponsorCodeString"
            @input="v$.sponsorCodeString.$touch()"
          />
          <div class="cd-form__buttons-row">
            <button
              :disabled="v$.$invalid || processing"
              class="cd-btn"
              @click.prevent="validateSponsorCode"
            >
              {{ i18n.global.t('form.sponsorcodeButton') }}
            </button>
          </div>
        </form>
      </div>
    </transition>
    <div v-if="formResponse" class="cd-flex" :class="`cd-form-${sponsorCodeError ? 'error' : 'success'}`">
      <svg v-if="sponsorCodeError" class="cd-mr-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M13,3A10,10,0,1,0,23,13,10,10,0,0,0,13,3Zm1,15H12V16h2Zm0-4H12V8h2Z" transform="translate(-3 -3)" fill="var(--cd-error-color)" /></svg>
      <svg v-else class="cd-mr-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M13,3A10,10,0,1,0,23,13,10,10,0,0,0,13,3ZM11,18,6,13l1.41-1.41L11,15.17l7.59-7.59L20,9Z" transform="translate(-3 -3)" fill="var(--cd-success-color)" />
      </svg>
      <p>
        {{ formResponse }}
      </p>
    </div>
  </div>
</template>
