<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  tabindex: {
    type: Number,
  },
  v: {
    type: Object,
    required: true,
  },
  ratePassword: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['update:modelValue', 'scoreUpdate', 'blur'])

const theme = inject('widgetTheme')
const i18n = inject('i18n')

const showPassword = ref(false)
const passwordScore = ref()
const inputType = ref('password')

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value
  if (showPassword.value)
    inputType.value = 'text'
  else
    inputType.value = 'password'
}

function inputEvent(e) {
  emits('update:modelValue', e.target.value)
  if (props.ratePassword) {
    passwordScore.value = e.target.value ? zxcvbn(e.target.value).score : null
    emits('scoreUpdate', zxcvbn(e.target.value))
  }
}

const errors = computed(() => props.v.$errors.slice(0, 1))

function getErrorMsg(err) {
  if (err) {
    if (err.$params.msg)
      return err.$params.msg
    if (err.$message)
      return err.$message
  }
}
</script>

<template>
  <div class="cd-form__input-container" :class="[theme !== 'default' ? `cd-${theme}-label` : '', errors.length > 0 ? 'cd-form-error' : '']">
    <template v-if="theme === 'material'">
      <input
        :id="name"
        :type="inputType"
        :value="modelValue"
        :name="name"
        maxlength="100"
        :tabindex="tabindex"
        :required="required"
        :placeholder="placeholder"
        autocomplete="off"
        @input="inputEvent"
        @blur="emits('blur')"
      >
      <div class="cd-form__label-container cd-flex">
        <label :for="name">{{ label }}</label>
        <span
          class="cd-form__input-toggle"
          @click="togglePasswordVisibility"
          v-html="showPassword ? i18n.global.t('form.hide') : i18n.global.t('form.show')"
        />
      </div>
    </template>
    <template v-else>
      <div class="cd-form__label-container cd-flex">
        <label :for="name">{{ label }}</label>
        <span
          class="cd-form__input-toggle"
          @click="togglePasswordVisibility"
          v-html="showPassword ? i18n.global.t('form.hide') : i18n.global.t('form.show')"
        />
      </div>
      <div class="cd-form__input">
        <input
          :id="name"
          :type="inputType"
          :value="modelValue"
          :name="name"
          maxlength="100"
          :tabindex="tabindex"
          :required="required"
          :placeholder="placeholder"
          autocomplete="off"
          @input="inputEvent"
          @blur="emits('blur')"
        >
      </div>
    </template>
    <PasswordMeter v-if="ratePassword" :score="passwordScore" />
    <FormError :message="getErrorMsg(errors[0])" />
  </div>
</template>
