<script setup>
import useVuelidate from '@vuelidate/core'
import { alphaNum, email, helpers, required } from '@vuelidate/validators'
import { creditCard, emailNoDomain, invalidChars } from '@/validation/validators'
import {
  customerData,
  externalLoginLink,
  hasSponsoredPlan,
  presetEmail,
  presetFirstName,
  presetLastName,
  signupMilestones,
} from '@/store'

const props = defineProps({
  signupApi: {
    type: Function,
    required: true,
  },
  t: {
    type: Function,
    required: true,
  },
})

const emits = defineEmits([
  'formError',
  'clearFormError',
  'updateCustomer',
  'nextStep',
  'dispatchStepEvent',
])

const memberSiteUrl = inject('memberSiteUrl')
const productName = inject('productName')
const processing = inject('processing')
const sponsorcodeLookupFailed = inject('sponsorcodeLookupFailed')
const supportlinkSignup = inject('supportlinkSignup')

watch(sponsorcodeLookupFailed, (newVal, oldVal) => {
  if (newVal)
    openSponsorModal.value = true
})

const emailDomain = ref('')

onMounted(() => {
  if (document.getElementsByName('company_email').length > 0)
    emailDomain.value = document.getElementsByName('company_email')[0].value
  emits('dispatchStepEvent')
})

/**
 * Form validation
 * Refs to track specific error cases and the needed UI changes
 * form <Object> ref for form inputs
 * rules <Object> vuelidate rules object
 */
const emailUnique = ref(true)
const openSponsorModal = ref(false)

const computedEmail = computed(() => {
  if (emailDomain.value)
    return `${form.value.email}@${emailDomain.value}`
  else return form.value.email
})

const emailMask = computed(() => {
  if (emailDomain.value)
    return { mask: 'Z*', tokens: { Z: { pattern: /[a-zA-Z0-9-_.]/ } } }
  else return null
})

const form = ref({
  firstName: presetFirstName.value
    ? presetFirstName.value
    : customerData.value?.firstName
      ? customerData.value.firstName
      : '',
  middleName: '',
  lastName: presetLastName.value
    ? presetLastName.value
    : customerData.value?.lastName
      ? customerData.value.lastName
      : '',
  email: presetEmail.value ? presetEmail.value : '',
  sponsorCodeString: '',
})

const rules = computed(() => ({
  firstName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(
      () => props.t('errors.invalidChars'),
      invalidChars,
    ),
  },
  middleName: {},
  lastName: {
    required: helpers.withMessage(() => props.t('errors.required'), required),
    invalidChars: helpers.withMessage(
      () => props.t('errors.invalidChars'),
      invalidChars,
    ),
  },
  email: {
    required: helpers.withMessage('', required),
    ...(!emailDomain.value && {
      email: helpers.withMessage(() => props.t('errors.email'), email),
    }),
    ...(emailDomain.value && {
      emailNoDomain: helpers.withMessage(
        () => props.t('errors.invalidChars'),
        emailNoDomain,
      ),
    }),
    unique: helpers.withMessage(
      () =>
        memberSiteUrl
          ? props.t('errors.EMAIL_USED', {
              loginLink: `<a href="${externalLoginLink.value}">${props.t('form.login')}</a>`,
            })
          : props.t('errors.EMAIL_USED', { loginLink: props.t('form.login') }),
      () => emailUnique.value,
    ),
  },
  sponsorCodeString: {
    ...(hasSponsoredPlan.value
      ? {
          alphaNum: helpers.withMessage(() => props.t('errors.invalidChars'), alphaNum),
          notCreditCard: helpers.withMessage(() => props.t('errors.SPONSOR_CODE_INVALID'), val => val?.length ? !creditCard(val) : true),
        }
      : { }),
  },
}))

const v$ = useVuelidate(rules, form, {})

/**
 * Handles input blur
 * touch triggers validation on blur, not input
 * if email field, lookup email for existing accounts
 */
async function inputBlur(input) {
  if (input === 'email')
    emailUnique.value = true

  if (form.value[input])
    v$.value[input].$touch()
}

/**
 * Exchange otc token for jwt and login
 *
 * @function
 */
async function getLoginToken(otc) {
  processing.value = true
  try {
    const response = await fetch(
      `${import.meta.env.VITE_WAPI_SERVER}/v1/login?code=${otc.code}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    )

    if (response.ok) {
      const resp = await response.json()

      if (resp?.errors) {
        emits('formError', resp.errors)
      }
      else {
        sessionStorage.setItem('wapi_token', resp.access_token)
        emits('updateCustomer', resp)
        emits('nextStep')
      }
      processing.value = false
    }
    else {
      emits('formError')
    }
  }
  catch {
    emits('formError')
  }
}

/**
 * Form submission
 * Progress is not stored in localStorage bc the account is not officially created on the backend until after the personal step
 *
 */
async function createCustomer() {
  try {
    const resp = await props.signupApi({
      url: supportlinkSignup.value ? '/api/signup/customer' : '/customer',
      method: 'POST',
      ...(supportlinkSignup.value && {
        relativeUrl: true,
      }),
      fields: {
        name: {
          firstName: form.value.firstName,
          middleName: form.value.middleName,
          lastName: form.value.lastName,
        },
        email: computedEmail.value,
        ...(form.value.sponsorCodeString && {
          sponsorCode: form.value.sponsorCodeString,
        }),
        trackingToken: customerData.value.trackingToken,
      },
      blocking: true,
      signupMilestone: signupMilestones.LEAD_GENERATED,
    })

    if (resp?.errors) {
      if (
        resp.errors[0]?.code === 'EMAIL_USED'
        || resp.errors[0]?.field === 'email'
      ) {
        emailUnique.value = false
        v$.value.email.$touch()
      }
      else {
        emits('formError', resp.errors)
      }
    }
    else {
      emits('updateCustomer', {
        ...resp,
        name: {
          firstName: form.value.firstName,
          middleName: form.value.middleName,
          lastName: form.value.lastName,
        },
        email: computedEmail.value,
      })

      if (resp?.otc)
        await getLoginToken(resp.otc)
      else emits('nextStep')
    }
  }
  catch {
    emits('formError')
  }
}
</script>

<script>
export default {
  name: 'AccountStep',
}
</script>

<template>
  <form role="form" novalidate="novalidate" class="cd-form">
    <fieldset>
      <p class="cd-form__legend">
        {{ t('headings.account', { product: productName }) }}
      </p>
      <div class="cd-form__input-group">
        <InputText
          v-model.trim="form.firstName"
          class="firstname cd-flex-1"
          name="firstname"
          :tabindex="1"
          :required="true"
          :label="t('form.firstName')"
          :autofocus="true"
          :v="v$.firstName"
          :t="t"
          @blur="inputBlur('firstName')"
        />
        <InputText
          v-model.trim="form.middleName"
          class="middlename cd-flex-1"
          name="middlename"
          :tabindex="2"
          :required="true"
          :label="t('form.middleName')"
          :v="v$.middleName"
          :t="t"
          @blur="inputBlur('middleName')"
        />
      </div>
      <InputText
        v-model.trim="form.lastName"
        class="lastname"
        name="lastname"
        :tabindex="3"
        :required="true"
        :label="t('form.lastName')"
        :v="v$.lastName"
        :t="t"
        @blur="inputBlur('lastName')"
      />
      <div
        :class="{ 'cd-form__email-domain-container': emailDomain.length > 0 }"
      >
        <InputText
          v-model="form.email"
          type="email"
          name="email"
          :tabindex="4"
          :required="true"
          :label="t('form.email')"
          :mask="emailMask"
          :v="v$.email"
          @blur="inputBlur('email')"
        >
          <p v-if="emailDomain.length > 0" class="cd-form__email-domain">
            @{{ emailDomain }}
          </p>
        </InputText>
      </div>
      <InputText
        v-if="hasSponsoredPlan"
        v-model.trim="form.sponsorCodeString"
        class="sponsorCodeString"
        name="sponsorCodeString"
        :tabindex="5"
        :label="t('form.sponsorcode')"
        :v="v$.sponsorCodeString"
        :t="t"
        @blur="inputBlur('sponsorCodeString')"
      />
    </fieldset>
    <div class="cd-form__buttons-row">
      <button
        type="submit"
        class="cd-btn"
        tabindex="6"
        :disabled="v$.$invalid || processing"
        @click.prevent="createCustomer()"
      >
        {{ t('form.step1Submit') }}
      </button>
    </div>
  </form>
</template>
